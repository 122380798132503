import React, { ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "common/icons/Icon/Icon";
import { Label } from "common/inputs/Label/Label";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { twClass } from "utils/twClass";

type Props = {
  children: ReactNode;
  className?: string;
  containerProps?: Record<string, unknown>;
  /** @deprecated Use the `_Field` pattern instead: https://www.notion.so/perdoo/Form-Fields-14c2140712794e44b2794ba2b6f30bd8?pvs=4#46216af0bac54332a3d9390e424d06e5  */
  errorText?: ReactNode;
  fieldAddOn?: ReactNode;
  helperText?: ReactNode;
  id?: string;
  infoElement?: ReactNode | null;
  label?: ReactNode;
  optional?: boolean;
};

export const FormControl = (props: Props): JSX.Element => {
  const {
    label = "",
    errorText = "",
    optional = false,
    infoElement = null,
    helperText,
    className = "",
    containerProps = {},
    children,
    fieldAddOn = null,
    id,
  } = props;

  const renderErrorText = () => {
    switch (true) {
      case typeof errorText === "string":
        return errorText;
      case React.isValidElement(errorText):
        return errorText;
      case errorText instanceof Error:
        return errorText.message;
      default:
        return null;
    }
  };

  return (
    <div
      className={twClass("mx-0 my-2 flex flex-col", className)}
      {...containerProps}
    >
      {(label || fieldAddOn || optional) && (
        <div className="mb-1 flex justify-end">
          {label && (
            <Label className="flex flex-grow items-center" htmlFor={id}>
              {label}
              <>
                {helperText && (
                  <WithTooltip
                    interactive
                    tooltip={<div className="max-w-xs">{helperText}</div>}
                  >
                    <Icon
                      className="ml-2 text-slate-500"
                      name="info"
                      size="md"
                      type="outlined"
                    />
                  </WithTooltip>
                )}
              </>
            </Label>
          )}
          {fieldAddOn}
          {optional && (
            <div className="text-sm text-slate-500">
              <FormattedMessage
                defaultMessage="Optional"
                id="global:form:optional"
              />
            </div>
          )}
        </div>
      )}
      {children}
      {errorText && (
        <div
          className="my-1 min-h-[18px] text-sm text-red-500"
          data-cy="errorMessage"
        >
          {renderErrorText()}
        </div>
      )}
      {!errorText && infoElement && (
        <div className="my-1 min-h-[18px] text-sm text-slate-500">
          {infoElement}
        </div>
      )}
    </div>
  );
};
