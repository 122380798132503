import { useState } from "react";
import { Timeframe } from "common/select/TimeframeSelect/TimeframeSelect.types";

export type ObjectiveFormHookType = {
  alignedToRadio: string | null;
  focusedField: string | null;
  setAlignedToRadio: (alignment: string | null) => void;
  setFocusedField: (fieldName: string | null) => void;
  setTimeframe: (timeframe: Timeframe | null) => void;
  timeframe: Timeframe | null;
};

interface Props {
  alignedField?: string;
}

export const useObjectiveForm = ({
  alignedField,
}: Props): ObjectiveFormHookType => {
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [timeframe, setTimeframe] = useState<Timeframe | null>(null);
  const [alignedToRadio, setAlignedToRadio] = useState<string | null>(
    alignedField ?? null
  );

  return {
    alignedToRadio,
    focusedField,
    setAlignedToRadio,
    setFocusedField,
    setTimeframe,
    timeframe,
  };
};
