import React from "react";
import { Clickable } from "common/navigation/Clickable";
import { twClass } from "utils/twClass";
import { useSelectContext } from "../Select.context";

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  showSelectValueAsText?: boolean;
};

export const SelectInlineValue = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      disabled = false,
      showSelectValueAsText = false,
      onClick,
      ...rest
    },
    ref
  ): JSX.Element => {
    const { isOpen, hasValue } = useSelectContext();

    return (
      <Clickable
        ref={ref}
        className={twClass(
          "h-10 w-full rounded py-px",
          "group/select transition-all ease-in-out",
          "outline-none",
          "border border-transparent -ml-2",
          {
            " hover:cursor-pointer hover:text-blue-500": showSelectValueAsText,
            "cursor-not-allowed": disabled,
            "data-[state=open]:bg-white data-[state=open]:border-slate-300":
              !disabled && !showSelectValueAsText,
            "divide-x": !showSelectValueAsText,
            "hover:bg-white hover:border-slate-300 hover:cursor-pointer hover:text-blue-500":
              !disabled && !showSelectValueAsText,
            "text-slate-800": !hasValue,
          },
          "px-2 truncate",
          className
        )}
        data-cy="selectValue"
        data-state={isOpen ? "open" : "closed"}
        onClick={disabled ? undefined : onClick}
        {...rest}
      >
        {children}
      </Clickable>
    );
  }
);

SelectInlineValue.displayName = "SelectInlineValue";
