import { FetchResult } from "@apollo/client";
import {
  CommitDefaultFieldsFragment,
  CommitDescriptionFragment,
  CommitStatus,
  CommitUserFragment,
  CommitValuesFragment,
  Maybe,
  MetricUnit,
  PerdooApiIntegrationApplicationChoices,
  PerdooApiKeyResultStatusChoices,
  PushIntegrationApplication,
  UpsertCommitMutationData,
} from "@graphql";
import classNames from "clsx";
import { Field, Form, Formik } from "formik";
import { isNil, isUndefined, toNumber } from "lodash";
import { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { TextButton } from "common/buttons";
import { Button } from "common/buttons/Button/Button";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { TextTruncate } from "common/collapsible";
import { InputField } from "common/fields/InputField/InputField";
import { CommitStatusField } from "common/form/CommitStatusField/CommitStatusField";
import { FormDatePicker } from "common/form/FormDatePicker/FormDatePicker";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { parseCommaSeparatedValues } from "common/form/utils/parseCommaSeparatedValues";
import { IntegrationIcon } from "common/goal/IntegrationIcon/IntegrationIcon";
import { Icon } from "common/icons/Icon/Icon";
import { IntegrationLogo } from "common/integration/IntegrationLogo/IntegrationLogo";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";
import { getMetricSymbol } from "constants/metric";
import { useCommitData } from "hooks/useCommitActions/useCommitData";
import { useFormSubmitOnShortcut } from "hooks/useFormSubmitOnShortcut/useFormSubmitOnShortcut";
import { useLocalToConfiguredTimezone } from "hooks/useLocalToConfiguredTimezone/useLocalToConfiguredTimezone";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { isSet } from "utils/isSet";
import { toast } from "utils/toastr";
import { twMerge } from "utils/twMerge";
import { formatPdMetric, isValidCommitNumber } from "utils/utils";
import { messages } from "./ProgressUpdatePanel.messages";

export type ProgressUpdatePanelTypes =
  | "kpi"
  | "result"
  | "edit"
  | "INITIATIVE"
  | "KEY_RESULT";

export type ProgressUpdatePanelValues = {
  commitDate?: string;
  description?: string | null;
  endValue?: number | null;
  status?: CommitStatus | PerdooApiKeyResultStatusChoices;
  value?: number | null;
};

type Props = {
  apiIntegration?: PushIntegrationApplication | null;
  application?: PerdooApiIntegrationApplicationChoices | null;
  backdating?: boolean;
  className?: string;
  initialValues: ProgressUpdatePanelValues;
  lastCommit?: Maybe<
    CommitDefaultFieldsFragment &
      CommitDescriptionFragment &
      CommitUserFragment &
      CommitValuesFragment
  >;
  metricUnit: MetricUnit;
  onClose?: () => void;
  onSubmit: (
    values: ProgressUpdatePanelValues
  ) => Promise<FetchResult<UpsertCommitMutationData>> | void;
  positiveMetric?: boolean;
  /** by default, we automatically convert to timezone defined in company settings.
   * but in kpi boards we're using UTC to make sure the commit is bucketed into the correct month.
   * @see perdoo_api/commits/models.py `from_kpi_boards` field.
   * */
  preserveTimezone?: boolean;
  resetGoal?: () => void;
  title: string;
  type: ProgressUpdatePanelTypes;
};

// TODO: refactor to use `common/overlay/Panel`
export const ProgressUpdatePanel = ({
  apiIntegration,
  application,
  backdating = false,
  className,
  initialValues,
  lastCommit,
  metricUnit,
  onClose,
  onSubmit,
  positiveMetric,
  preserveTimezone,
  resetGoal,
  title,
}: Props): JSX.Element => {
  const intl = useIntl();
  const panelRef = useRef<HTMLDivElement>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const { formRef } = useFormSubmitOnShortcut<ProgressUpdatePanelValues>();

  const {
    delta,
    deltaColorClass,
    deltaIcon,
    description,
    lastUpdateString,
    value,
    valueBefore,
  } = useCommitData(lastCommit, positiveMetric);

  const { toConfiguredTimezone } = useLocalToConfiguredTimezone();

  const validationSchema = () => {
    return Yup.object().shape({
      commitDate: Yup.date().max(
        new Date(),
        intl.formatMessage({
          defaultMessage: "Date cannot be in future",
          id: "DAtAJK",
        })
      ),
      description: Yup.string().nullable(),
      status: initialValues.status
        ? Yup.string().oneOf(Object.values(CommitStatus)).required()
        : Yup.string().nullable(),
      value: Yup.string()
        .required("Required")
        .test(
          "is-number",
          intl.formatMessage(messages.validationErrorValue),
          isValidCommitNumber
        ),
    });
  };

  const handleClose = () => {
    const formTouched = formRef.current?.dirty ?? false;
    if (formTouched && !showConfirm) {
      setShowConfirm(true);
    } else {
      onClose?.();
    }
  };

  const handleSubmit = async (values: ProgressUpdatePanelValues) => {
    let commitDate = values.commitDate;
    if (commitDate && !preserveTimezone) {
      commitDate = toConfiguredTimezone(commitDate);
    }

    // we cannot set input type as number, because we want to accept values like "1,50" and ".95".
    // so, value is coming in as string, but its expected type is number, so we need to convert it before sending.
    const parsedValue = toNumber(parseCommaSeparatedValues(`${values.value}`));
    const parsedValues = {
      ...values,
      commitDate,
      value: parsedValue,
    };

    const response = await onSubmit(parsedValues);
    if (response) {
      const { errors, data } = response;
      if (errors) {
        toast.failure(
          intl.formatMessage({
            defaultMessage:
              "Something went wrong. Please contact our Support Team if this happens again.",
            id: "global:something:wrong:extended",
          })
        );
      } else if (!isNil(data?.upsertCommit?.commit)) {
        toast.success(intl.formatMessage(messages.toastSuccess));
      }
    }

    onClose?.();
  };

  useOutsideAlerter(panelRef, handleClose, [
    // Mentions list
    "[data-mentions-list]",
    "#dropdown-root",
    "em-emoji-picker",
    "#modal-confirm",
    "#overlay-root",
    "#select-dropdown-portal",
    // Not sure why, but clicking on the status in Select registers as an outside click. so whitelisting explicitly here.
    "#statusElement",
    "#text-editor-tools-root",
    "#gif-picker",
    "#image-picker",
  ]);

  const suffix = isSet(initialValues.endValue) ? (
    <span className="flex gap-1 divide-x divide-slate-300">
      <span>{`/${formatPdMetric(intl, initialValues.endValue, null, {
        abbreviate: true,
      })}`}</span>
      {apiIntegration && (
        <IntegrationIcon className="pl-1" integration={apiIntegration} />
      )}
      {application && (
        <div className="flex items-center pl-2">
          <IntegrationLogo application={application} size="small" />
        </div>
      )}
    </span>
  ) : null;

  return (
    // reason: the onClick is just for stopping event propagation
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <article
      ref={panelRef}
      aria-label="progressUpdatePanel"
      className={twMerge(
        "flex flex-col",
        "divide-y divide-slate-200",
        "rounded bg-white",
        className
      )}
      data-cy="progressUpdatePanel"
      data-testid="progressUpdatePanel"
      // prevent triggering fold / unfold events on roadmap
      onClick={(e) => e.stopPropagation()}
    >
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting, setFieldValue, touched }) => (
          <Form>
            <div
              className="flex items-center justify-between border-b px-5 py-2"
              id="progressPanelHeader"
            >
              <h4>{title}</h4>
              <IconButton
                className="text-slate-500"
                data-cy="xvaLGUpskAr-amMT42ETd"
                name="close"
                onClick={handleClose}
                size="xl"
              />
            </div>
            <div
              className="flex flex-col gap-2 px-5 py-4"
              id="progressPanelBody"
            >
              {isSet(lastCommit) && (
                <div>
                  <div className="flex flex-wrap items-center text-xs">
                    <span className="flex-shrink text-slate-500">
                      {lastUpdateString}
                    </span>
                    <span className="ml-1 font-semibold">
                      <FormattedMetric value={valueBefore} /> →{" "}
                      <FormattedMetric value={value} />
                    </span>
                    <span
                      className={`ml-1 inline-flex items-center ${deltaColorClass}`}
                    >
                      <Icon name={deltaIcon} size="xs" />
                      <span>
                        <FormattedMetric value={delta} />
                      </span>
                    </span>
                  </div>
                  <TextTruncate collapsedHeight={60}>
                    <div
                      className={classNames("w-full text-sm", {
                        "text-slate-500": !lastCommit.description,
                      })}
                    >
                      {description}
                    </div>
                  </TextTruncate>
                </div>
              )}
              <div className="flex justify-between gap-3">
                <div className="w-1/2">
                  <InputField
                    prefix={getMetricSymbol(metricUnit)}
                    aria-label="value"
                    autoFocus
                    data-cy="progressValueInput"
                    label={intl.formatMessage(messages.valueLabel)}
                    name="value"
                    onChange={(e) =>
                      setFieldValue(
                        "value",
                        parseCommaSeparatedValues(e.target.value)
                      )
                    }
                    suffix={suffix}
                    // sic: this should not be type="number" or we won't be able to accept values like ".95" or "1,25"
                  />
                </div>
                {!isUndefined(initialValues.status) && (
                  <CommitStatusField
                    className="w-1/2"
                    data-cy="progressStatusInput"
                    label={intl.formatMessage(messages.statusLabel)}
                    name="status"
                  />
                )}
              </div>
              <Field
                aria-label="description"
                className="min-h-24 max-h-48"
                // TODO: cannot use FormTextArea in storybook. fix / mock queries.
                component={FormTextArea}
                data-cy="progressDescriptionInput"
                errorText={touched.description && errors.description}
                label={intl.formatMessage(messages.commentLabel)}
                name="description"
                optional
                placeholder={intl.formatMessage(messages.commentPlaceholder)}
              />
              {backdating && (
                <Field
                  aria-label="commitDate"
                  component={FormDatePicker}
                  data-cy="progressDateInput"
                  date={new Date()}
                  errorText={touched.commitDate && errors.commitDate}
                  label={intl.formatMessage(messages.dateLabel)}
                  maxDate={new Date()}
                  name="commitDate"
                  optional
                  showTimeInput
                />
              )}
            </div>
            <div
              className={classNames(
                "flex items-center justify-between",
                "rounded-b border-t bg-slate-50",
                "px-5 py-4"
              )}
              id="progressPanelFooter"
            >
              {resetGoal ? (
                <TextButton
                  className="pl-0 text-base"
                  data-cy="VPebsNtUeHF0GiHBGUa6c"
                  onClick={() => resetGoal()}
                  size="small"
                  text={intl.formatMessage(messages.clearDraft)}
                />
              ) : null}
              <div className="w-full flex gap-2 justify-end">
                <Button
                  data-cy="a_Q4XPippJ-tEsBoKr5CH"
                  onClick={handleClose}
                  variant="ghost"
                >
                  {intl.formatMessage({
                    defaultMessage: "Cancel",
                    id: "global:cancel",
                  })}
                </Button>
                <Button data-cy="submit" loading={isSubmitting} type="submit">
                  {intl.formatMessage(messages.done)}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showConfirm && (
        <ConfirmationModal
          action={onClose}
          close={() => setShowConfirm(false)}
          confirmText={intl.formatMessage(messages.confirmButtonLabel)}
          title={intl.formatMessage(messages.confirmTitle)}
        >
          <FormattedMessage {...messages.confirmDescription} />
        </ConfirmationModal>
      )}
    </article>
  );
};
