import Fuse from "fuse.js";
import { useIntl } from "react-intl";
import { messages } from "./TimeframeSelect.messages";
import { Timeframe } from "./TimeframeSelect.types";

export const searchTimeframes = (options: Timeframe[], query: string) => {
  if (!query) return options;

  return new Fuse(options, {
    findAllMatches: true,
    isCaseSensitive: false,
    keys: ["name"],
    threshold: 0.2,
  })
    .search(query)
    .map((result) => result.item);
};

export const groupTimeframes = (
  groups: { key: string; timeframes: Timeframe[] }[],
  visibleOptions: Timeframe[],
  intl: ReturnType<typeof useIntl>
): { label: string; timeframes: Timeframe[] }[] => {
  const visibleOptionIds = new Set(visibleOptions.map((option) => option.id));

  return groups
    .map(({ key, timeframes }) => ({
      label: intl.formatMessage(messages[key as keyof typeof messages]),
      timeframes: timeframes.filter((timeframe) =>
        visibleOptionIds.has(timeframe.id)
      ),
    }))
    .filter(({ timeframes }) => timeframes.length > 0);
};
