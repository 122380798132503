import {
  namedOperations,
  useGetCompanyClosingTemplateQuery,
  useUpsertResultMutation,
} from "@graphql";
import { FormikHelpers } from "formik";
import { useRouter } from "next/router";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { OBJECTIVE_QUERY } from "common/objective/queries";
import { Modal } from "common/overlay/Modal/Modal";
import { ARCHIVE_RESULT, RESTORE_RESULT } from "constants/tracking";
import { handleNonFormErrors, setFormikGqlErrors } from "utils/forms";
import { track } from "utils/tracker";
import { twClass } from "utils/twClass";
import { showServerErrorToast } from "utils/utils";
import {
  ArchiveResultForm,
  ArchiveResultFormValues,
} from "./ArchiveResultForm/ArchiveResultForm";

const messages = defineMessages({
  archiveTitle: {
    defaultMessage: "Archive Result",
    id: "result:archive:title",
  },
});

interface ArchiveResultModalProps {
  callback: () => void;
  id: string;
  objectiveId?: string;
  onRequestClosed: () => void;
  open: boolean;
  progress?: number;
}

export const ArchiveResultModal = ({
  open,
  id,
  objectiveId,
  onRequestClosed,
  progress,
  callback,
}: ArchiveResultModalProps): JSX.Element | null => {
  const { data, error } = useGetCompanyClosingTemplateQuery();
  const intl = useIntl();
  const {
    query: { stack },
  } = useRouter();
  const [editResult] = useUpsertResultMutation({
    refetchQueries: [
      {
        query: OBJECTIVE_QUERY,
        variables: {
          objectiveId,
        },
      },
      namedOperations.Query.resultsQuery,
      namedOperations.Query.getObjective,
      namedOperations.Query.getObjectiveTimeline,
      namedOperations.Query.getObjectiveResults,
      namedOperations.Query.getUserOkrs,
    ],
  });

  if (!data || error) return null;

  const trackArchiveResult = (
    result: { archived: boolean; archivedComment: string; id: string },
    progress?: number
  ) => {
    track(result.archived ? ARCHIVE_RESULT : RESTORE_RESULT, {
      progressAtArchive: progress,
    });
  };
  // TODO: [no-unnecessary-condition] remove and fix
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const initArchivingTemplate = data?.currentCompany.initArchivingTemplate;

  const onSubmit = (
    values: ArchiveResultFormValues,
    actions: FormikHelpers<ArchiveResultFormValues>
  ) => {
    const input = {
      archived: true,
      archivedComment: values.comment,
      id,
    };

    editResult({
      variables: {
        input,
      },
    })
      .then((response) => {
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const errors = response?.data?.upsertResult?.errors;
        if (errors?.length) {
          setFormikGqlErrors(actions, errors);
          handleNonFormErrors(errors);
          return;
        }
        // TODO: [no-unnecessary-condition] remove and fix
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        callback?.();
        onRequestClosed();
        trackArchiveResult(input, progress);
      })
      .catch((err) => showServerErrorToast(err))
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Modal
      backdropClass={twClass({ "!z-modal-prio-backdrop": stack })}
      backdropClose={false}
      className={twClass({ "!z-modal-prio": stack })}
      isOpen={open}
      onClose={onRequestClosed}
      size="sm"
      title={intl.formatMessage(messages.archiveTitle)}
    >
      <ArchiveResultForm
        initialValues={{ comment: initArchivingTemplate }}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
