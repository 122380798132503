import {
  namedOperations,
  useGetUserQuery,
  useUpsertUserMutation,
} from "@graphql";
import { isNil, pick } from "lodash";
import { useIntl } from "react-intl";
import { FullPageSpinner } from "common";
import { Modal } from "common/overlay/Modal/Modal";
import { UserForm } from "common/user/UserForm/UserForm";
import { handleErrors } from "utils/graphql/handleErrors";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onRequestClosed' implicitly has a... Remove this comment to see the full error message
export const UserProfileEditModal = ({ onRequestClosed, userId, open }) => {
  const intl = useIntl();
  const { data } = useGetUserQuery({
    // makes sure changes made on related users (direct reports, manager) are reflected.
    fetchPolicy: "cache-and-network",
    variables: { id: userId },
  });
  const user = data?.user;
  const [updateUser] = useUpsertUserMutation();

  if (isNil(user)) return <FullPageSpinner />;

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'values' implicitly has an 'any' type.
  const onSubmit = async (values, actions) => {
    const response = await updateUser({
      refetchQueries: [
        namedOperations.Query.getSupervisees,
        namedOperations.Query.getCurrentUserInfo,
        namedOperations.Query.GetAllPerformanceReviews,
        namedOperations.Query.UserGroups,
      ],
      variables: {
        input: pick(values, [
          "password",
          "directReports",
          "email",
          "firstName",
          "groups",
          "id",
          "jobPosition",
          "lastName",
          "manager",
          "role",
        ]),
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.upsertUser?.errors
    );
    if (hasError) {
      actions.setSubmitting(false);
      return;
    }
    toast.success(
      intl.formatMessage({
        defaultMessage: "Changes saved",
        id: "nQKiSH",
      })
    );
    onRequestClosed();
  };

  return (
    <Modal
      backdropClose={false}
      isOpen={open}
      onClose={onRequestClosed}
      showFooter={false}
      title="Edit User"
    >
      <UserForm
        initialValues={{
          ...user,
          directReports: mapEdges(user.directReports.edges).map((g) => g.id),
          groups: mapEdges(user.groups.edges).map((g) => g.id),
          manager: user.manager?.id,
        }}
        isEdit
        onSubmit={onSubmit}
      />
    </Modal>
  );
};
