import { FormikProps } from "formik";
import { useCallback, useEffect, useRef } from "react";

type FormRef<T> = React.RefObject<FormikProps<T>>;

type Hook<T> = {
  formRef: FormRef<T>;
};

const formStack: FormRef<any>[] = [];

/**
 * Custom React hook to handle form submission when a specific keyboard shortcut is pressed.
 *
 * This hook integrates with Formik forms and listens for the "Enter" key press combined with either "Ctrl" or "Meta"
 * keys. When the shortcut is detected, it triggers the form submission if the form is not already submitting.
 *
 * @template T - The type of the form values managed by Formik.
 * @returns {Hook<T>} An object containing a reference to the Formik form.
 */
export const useFormSubmitOnShortcut = <T>(): Hook<T> => {
  const formRef = useRef<FormikProps<T>>(null);

  /**
   * Activates the form by adding it to the form stack.
   *
   * This ensures that the keyboard shortcut is only enabled for the most recently activated form.
   */
  const activate = useCallback(() => {
    formStack.push(formRef);
  }, []);

  /**
   * Deactivates the form by removing it from the form stack.
   *
   * This is necessary to disable the keyboard shortcut when the form is unmounted or no longer the most recent form.
   */
  const deactivate = useCallback(() => {
    const index = formStack.indexOf(formRef);
    if (index > -1) {
      formStack.splice(index, 1);
    }
  }, []);

  useEffect(() => {
    activate();
    return () => deactivate();
  }, [activate, deactivate]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the current form is the most recent one in the stack
      if (formStack[formStack.length - 1] !== formRef) return;

      const form = formRef.current;

      // If the form is not available, exit early
      if (!form) return;

      const isSubmitShortcut =
        event.key === "Enter" && (event.ctrlKey || event.metaKey);

      // If the form is already submitting or the shortcut keys are not pressed, exit early
      if (form.isSubmitting || !isSubmitShortcut) return;

      // Prevent default form submission and trigger Formik's submitForm method
      event.preventDefault();
      form.submitForm();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return { formRef };
};
