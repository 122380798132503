import { defineMessages } from "react-intl";

export const messages = defineMessages({
  activeTimeframes: {
    defaultMessage: "Active timeframes",
    id: "timeframe:dropdown:activeTimeframe",
  },
  allTimeframes: {
    defaultMessage: "All timeframes",
    id: "timeframe:dropdown:allTimeframe",
  },
  futureTimeframes: {
    defaultMessage: "Future timeframes",
    id: "timeframe:dropdown:futureTimeframe",
  },
  noTimeframes: {
    defaultMessage: "No timeframe found",
    id: "timeframe:dropdown:noTimeframeFound",
  },
  pastTimeframes: {
    defaultMessage: "Past timeframes",
    id: "timeframe:dropdown:pastTimeframe",
  },
  placeholder: {
    defaultMessage: "Select...",
    id: "724CrE",
  },
});
