const NEW_LINE = `%0D%0A`;

export const getNudgeMailtoMessage = ({
  frequency = "weekly",
  recipient = "",
  sender = "",
}: Record<string, string>) => {
  const greeting = recipient ? `Hi ${recipient},` : "Hi,";

  return {
    noCheckInThisWeek: {
      body: [
        `${greeting}${NEW_LINE}`,
        "Your Review for this week is still due. Please submit your Review as soon as possible: https://web.perdoo.com/#/checkin%0D%0A",
        "Many thanks in advance!",
        `${sender},`,
      ].join(NEW_LINE),
      subject: `Your ${frequency} Review is still due.`,
    },
  };
};
