export type MetricValue =
  | "NUMERICAL"
  | "PERCENTAGE"
  | "EUR"
  | "USD"
  | "GBP"
  | "AUD"
  | "BRL"
  | "CAD"
  | "CHF"
  | "CLP"
  | "CNY"
  | "CZK"
  | "DKK"
  | "HKD"
  | "HUF"
  | "IDR"
  | "ILS"
  | "INR"
  | "JPY"
  | "KRW"
  | "MXN"
  | "MYR"
  | "NOK"
  | "NZD"
  | "PHP"
  | "PKR"
  | "PLN"
  | "RON"
  | "RUB"
  | "SEK"
  | "SGD"
  | "THB"
  | "TRY"
  | "TWD"
  | "ZAR";

export const MetricUnits = {
  AUD: "AUD",
  BRL: "BRL",
  CAD: "CAD",
  CHF: "CHF",
  CLP: "CLP",
  CNY: "CNY",
  CZK: "CZK",
  DKK: "DKK",
  EUR: "EUR",
  GBP: "GBP",
  HKD: "HKD",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  JPY: "JPY",
  KRW: "KRW",
  MXN: "MXN",
  MYR: "MYR",
  NOK: "NOK",
  NUMERICAL: "NUMERICAL",
  NZD: "NZD",
  PERCENTAGE: "PERCENTAGE",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  RON: "RON",
  RUB: "RUB",
  SEK: "SEK",
  SGD: "SGD",
  THB: "THB",
  TRY: "TRY",
  TWD: "TWD",
  USD: "USD",
  ZAR: "ZAR",
} as const;

export const FrequentMetrics = {
  [MetricUnits.NUMERICAL]: {
    abbreviation: MetricUnits.NUMERICAL,
    name: "Numerical",
    symbol: "#",
  },
  [MetricUnits.PERCENTAGE]: {
    abbreviation: MetricUnits.PERCENTAGE,
    name: "Percentage",
    symbol: "%",
  },
};

export const FrequentCurrencies = {
  [MetricUnits.EUR]: {
    abbreviation: MetricUnits.EUR,
    name: "Euro",
    symbol: "€",
  },
  [MetricUnits.USD]: {
    abbreviation: MetricUnits.USD,
    name: "United States Dollar",
    symbol: "$",
  },
  [MetricUnits.GBP]: {
    abbreviation: MetricUnits.GBP,
    name: "United Kingdom Pound",
    symbol: "£",
  },
};

export const OtherCurrencies = {
  [MetricUnits.AUD]: {
    abbreviation: MetricUnits.AUD,
    name: "Australia Dollar",
    symbol: "$",
  },
  [MetricUnits.BRL]: {
    abbreviation: MetricUnits.BRL,
    name: "Brazil Real",
    symbol: "R$",
  },
  [MetricUnits.CAD]: {
    abbreviation: MetricUnits.CAD,
    name: "Canada Dollar",
    symbol: "$",
  },
  [MetricUnits.CHF]: {
    abbreviation: MetricUnits.CHF,
    name: "Switzerland Franc",
    symbol: "CHF",
  },
  [MetricUnits.CLP]: {
    abbreviation: MetricUnits.CLP,
    name: "Chile Peso",
    symbol: "$",
  },
  [MetricUnits.CNY]: {
    abbreviation: MetricUnits.CNY,
    name: "China Yuan Renminbi",
    symbol: "¥",
  },
  [MetricUnits.CZK]: {
    abbreviation: MetricUnits.CZK,
    name: "Czech Republic Koruna",
    symbol: "Kč",
  },
  [MetricUnits.DKK]: {
    abbreviation: MetricUnits.DKK,
    name: "Denmark Krone",
    symbol: "kr",
  },
  [MetricUnits.HKD]: {
    abbreviation: MetricUnits.HKD,
    name: "Hong Kong Dollar",
    symbol: "$",
  },
  [MetricUnits.HUF]: {
    abbreviation: MetricUnits.HUF,
    name: "Hungary Forint",
    symbol: "Ft",
  },
  [MetricUnits.IDR]: {
    abbreviation: MetricUnits.IDR,
    name: "Indonesia Rupiah",
    symbol: "Rp",
  },
  [MetricUnits.ILS]: {
    abbreviation: MetricUnits.ILS,
    name: "Israel Shekel",
    symbol: "₪",
  },
  [MetricUnits.INR]: {
    abbreviation: MetricUnits.INR,
    name: "India Rupee",
    symbol: "₹",
  },
  [MetricUnits.JPY]: {
    abbreviation: MetricUnits.JPY,
    name: "Japan Yen",
    symbol: "¥",
  },
  [MetricUnits.KRW]: {
    abbreviation: MetricUnits.KRW,
    name: "Korea (South) Won",
    symbol: "₩",
  },
  [MetricUnits.MXN]: {
    abbreviation: MetricUnits.MXN,
    name: "Mexico Peso",
    symbol: "$",
  },
  [MetricUnits.MYR]: {
    abbreviation: MetricUnits.MYR,
    name: "Malaysia Ringgit",
    symbol: "RM",
  },
  [MetricUnits.NOK]: {
    abbreviation: MetricUnits.NOK,
    name: "Norway Krone",
    symbol: "kr",
  },
  [MetricUnits.NZD]: {
    abbreviation: MetricUnits.NZD,
    name: "New Zealand Dollar",
    symbol: "$",
  },
  [MetricUnits.PHP]: {
    abbreviation: MetricUnits.PHP,
    name: "Philippines Peso",
    symbol: "₱",
  },
  [MetricUnits.PKR]: {
    abbreviation: MetricUnits.PKR,
    name: "Pakistan Rupee",
    symbol: "₨",
  },
  [MetricUnits.PLN]: {
    abbreviation: MetricUnits.PLN,
    name: "Poland Zloty",
    symbol: "zł",
  },
  [MetricUnits.RON]: {
    abbreviation: MetricUnits.RON,
    name: "Romania Leu",
    symbol: "lei",
  },
  [MetricUnits.RUB]: {
    abbreviation: MetricUnits.RUB,
    name: "Russia Ruble",
    symbol: "₽",
  },
  [MetricUnits.SEK]: {
    abbreviation: MetricUnits.SEK,
    name: "Sweden Krona",
    symbol: "kr",
  },
  [MetricUnits.SGD]: {
    abbreviation: MetricUnits.SGD,
    name: "Singapore Dollar",
    symbol: "$",
  },
  [MetricUnits.THB]: {
    abbreviation: MetricUnits.THB,
    name: "Thailand Baht",
    symbol: "฿",
  },
  [MetricUnits.TRY]: {
    abbreviation: MetricUnits.TRY,
    name: "Turkey Lira",
    symbol: "₺",
  },
  [MetricUnits.TWD]: {
    abbreviation: MetricUnits.TWD,
    name: "Taiwan New Dollar",
    symbol: "NT$",
  },
  [MetricUnits.ZAR]: {
    abbreviation: MetricUnits.ZAR,
    name: "South Africa Rand",
    symbol: "R",
  },
};

export const AllMetrics = {
  ...FrequentMetrics,
  ...FrequentCurrencies,
  ...OtherCurrencies,
};

export const getMetricSymbol = (metricUnit: MetricValue): string => {
  const metricData = AllMetrics[metricUnit];
  return metricData.symbol;
};
