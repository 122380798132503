import { CompanyFeatures } from "hooks/useCompanyFeatures/useCompanyFeatures.types";

export const ACTIVATE_ACCOUNT = "Activate account";
export const ADD_BOOKMARK = "Add bookmark";
export const ADD_CADENCE = "Add cadence";
export const ADD_CHECK_IN = "Add web check-in";
export const ADD_COMMENT = "Add comment";
export const ADD_GROUP = "Add group";
export const ADD_INITIATIVE = "Add initiative";
export const ADD_JIRA_INTEGRATION = "Add JIRA integration";
export const ADD_ASANA_INTEGRATION = "Add ASANA integration";
export const ADD_DIRECT_REPORTS = "Add direct reports";
export const ADD_KEY_RESULT = "Add key result";
export const ADD_KPI = "Add KPI";
export const ADD_KPI_TARGET = "Add KPI target";
export const ADD_KPI_BOARD = "Add KPI board";
export const ADD_OBJECTIVE = "Add objective";
export const ADD_ONE_ON_ONE_MEETING = "Add 1:1 meeting";
export const EDIT_ONE_ON_ONE_MEETING = "Edit 1:1 meeting";
export const ONE_ON_ONE_MEETING_ITEM_ADDED = "Added 1:1 meeting item";
export const ADD_PERFORMANCE_REVIEW = "Add Performance Review";
export const ADD_SLACK_INTEGRATION = "Add Slack integration";
export const ADD_STRATEGIC_PILLAR = "Add Strategic Pillar";
export const ADD_TEAM_REVIEW = "Add Team Review";
export const ADD_TIMEFRAMES = "Add timeframes";
export const ADD_USER = "Add user";
export const ARCHIVE_CADENCE = "Archive cadence";
export const ARCHIVE_RESULT = "Archive Result";
export const ASANA_PROJECT_OR_TASK_LINKED = "Linked ASANA";
export const CANCEL_CLOSE_OBJECTIVE = "Cancel Close Objective";
export const CHANGE_CADENCE = "Change cadence";
export const CHANGE_SUB_TEAM_VIEW = "Change sub-team view";
export const CHANGE_PAGE_SIZE = "Change page size";
export const CHANGE_PROFILE_FILTER = "Profile filter changed";
export const CHAT_TO_SALES = "Clicked Chat to sales";
export const CHAT_TO_OUR_TEAM = "Clicked Chat to our team";
export const CHECKOUT_COMPLETE = "Check-out complete";
export const CLICK_NOTIFICATION = "Clicked notification";
export const CLICK_SHARE = "Click Share";
export const CLONE_OBJECTIVE = "Clone Objective";
export const CLOSE_OBJECTIVE = "Close Objective";
export const CLOSE_OBJECTIVE_STARTED = "Close Objective Started";
export const COLUMN_ADD = "Add Column";
export const COLUMN_REMOVE = "Remove Column";
export const CHECK_IN_COMMENT_ADDED = "Check-in Comment Added";
export const CHECK_IN_COMMENT_EDITED = "Check-in Comment Edited";
export const START_WELCOME_FLOW = "Start welcome flow";
export const COMPLETE_WELCOME_FLOW = "Complete welcome flow";
export const CONTEXTUAL_SIDEBAR_CHECK_IN_NAVIGATION =
  "Check-in navigation in contextual sidebar";
export const CONTEXTUAL_SIDEBAR_SWITCH = "Switch view in contextual sidebar";
export const COPY_INVITE_LINK = "Copy Invite Link";
export const CORRECT_STREAK = "Correct streak";
export const CSV_EXPORT = "CSV export";
export const DELETE_BOOKMARK = "Delete bookmark";
export const DELETE_CADENCE = "Delete cadence";
export const DELETE_KEY_RESULT = "Delete Key Result";
export const DELETE_KPI = "Delete KPI";
export const DELETE_KPI_TARGET = "Delete KPI target";
export const DELETE_ONE_ON_ONE_RELATIONSHIP = "Delete 1:1 relationship";
export const DELETE_OBJECTIVE = "Delete Objective";
export const DELETE_STRATEGIC_PILLAR = "Delete Strategic Pillar";
export const DELETE_TIMEFRAME = "Delete timeframe";
export const EDIT_CADENCE = "Edit cadence";
export const EDIT_COMPANY = "Edit Company";
export const EDIT_OBJECTIVE = "Edit Objective";
export const EDIT_RESULT = "Edit Result";
export const EDIT_KPI = "Edit KPI";
export const CHANGE_DEFAULT_GOAL_UPDATE_CYCLE = "Update frequency changed";
export const EDIT_MISSION_VISION = "Edit Mission and Vision";
export const EDIT_PERFORMANCE_REVIEW = "Edit Performance Review";
export const EDIT_SLACK_INTEGRATION = "Edit Slack integration";
export const EDIT_TIMEFRAME = "Edit timeframe";
export const EXPAND_KPIS_ROADMAP = "Expand KPIs on aligned roadmap";
export const FOLD_KPIS_ROADMAP = "Fold KPIs on aligned roadmap";
export const TOGGLE_CARD_SIZE_ROADMAP = "Toggle card size on Roadmap";
export const FOLD_MAPS_ALL_CARD = "All cards folded on aligned roadmap";
export const FOLD_MAPS_CARD = "Card folded on aligned roadmap";
export const GCAL_CONNECTED = "Google Calendar integration connected";
export const GCAL_DISCONNECTED = "Google Calendar integration disconnected";
export const GCAL_ONE_ON_ONE_EVENT_LINKED = "1:1 linked to Google event";
export const GCAL_ONE_ON_ONE_EVENT_UNLINKED = "1:1 unlinked from Google event";
export const GCAL_ONE_ON_ONE_EVENT_CREATED = "1:1 event created in Google";
export const GROWTH_BOARD_ADDED = "KPI added to Growth Board";
export const GROWTH_BOARD_NAVIGATION = "Growth Board navigation";
export const GROWTH_BOARD_REORDERED = "Growth Board reordered";
export const GROWTH_BOARD_REMOVED = "KPI removed from Growth Board";
export const HELP_CENTER_VIEW = "View Help Center";
export const HELP_CENTER_CONTENT = "Click on Help Center content";
export const INSIGHTS_DRILLDOWN = "Insights drill-down";
export const INVITE_RESERVED_USER = "Invite reserved user";
export const JIRA_ISSUE_LINKED = "Linked JIRA issue";
export const KUDOS_SENT = "Kudos sent";
export const REACTION_SET = "Reaction set";
export const MEETING_ITEM_TEMPLATE_ADDED = "Meeting item template added";
export const MEETING_ITEM_TEMPLATE_DELETED = "Meeting item template deleted";
export const MEETING_ITEM_TEMPLATE_UPDATED = "Meeting item template updated";
export const MS_TEAMS_LOGIN = "MS Teams Login";
export const MS_TEAMS_PINNED = "MS Perdoo Group Pinned";
export const OPEN_ADD_MODAL = "Open add modal";
export const LOGOUT = "Logout";
export const MAPS_TOGGLE = "Maps toggle";
export const ONE_ON_ONE_MEETING_COMPLETED = "ONE_ON_ONE_MEETING_COMPLETED";
export const ONE_ON_ONE_MEETING_CANCELLED = "ONE_ON_ONE_MEETING_CANCELLED";
export const ONE_ON_ONE_MEETING_RESCHEDULED = "ONE_ON_ONE_MEETING_RESCHEDULED";
export const ONE_ON_ONE_SERIES_DISABLED = "ONE_ON_ONE_SERIES_DISABLED";
export const ONE_ON_ONE_SERIES_ENABLED = "ONE_ON_ONE_SERIES_ENABLED";
export const DUE_DATE_ADDED_TO_ACTION_ITEM = "DUE_DATE_ADDED_TO_ACTION_ITEM";
export const ACTION_ITEM_CHECKED = "ACTION_ITEM_CHECKED";
export const OUT_OF_OFFICE_ADDED = "Out of office added";
export const OUT_OF_OFFICE_REMOVED = "Out of office removed";
export const PERFORMANCE_REVIEW_COMPLETED = "Performance Review completed";
export const PERFORMANCE_REVIEW_OPENED = "Performance Review form opened";
export const PERFORMANCE_REVIEW_SAVED = "Performance Review saved";
export const PDF_EXPORT = "PDF export";
export const PERFORMANCE_REPORT_TAB_CLICK = "Click Performance Report tab";
export const PREMIUM_CALL_SCHEDULED = "Schedule call successfully";
export const PREMIUM_CHECKOUT = "Upgrade checkout page";
export const PREMIUM_UPGRADE_MODAL = "Upgrade plans page";
export const PRINT = "Print";
export const PRIVATE_MODE_ON = "Enable private mode";
export const QUERY_INSIGHTS = "Query insights";
export const RATE_CHECK_IN = "Rate check-in";
export const REFLECTION_CHANNEL_SET = "Reflection channel set";
export const REOPEN_OBJECTIVE = "Re-open Objective";
export const REORDER = "Re-order";
export const REPORT_DRILLDOWN = "Open report drilldown";
export const RESET_TO_DEFAULT_TEMPLATES = "Reset to Default Templates";
export const RESTORE_CADENCE = "Restore cadence";
export const RESTORE_RESULT = "Restore Result";
export const ROADMAP_ZOOM = "Roadmap Zoom";
export const SAVE_TEMPLATES = "Save templates";
export const SELECT_EXAMPLE_GOAL = "Select example goal";
export const SEND_INVITE = "Send Invite";
export const START_TRIAL = "Started trial";
export const SUB_GROUPS_FILTER_TOGGLE = "Toggle sub-groups filter";
export const SUB_GOALS_FILTER_TOGGLE = "Toggle sub-goals filter";
export const SUPPORT_VIEWED = "Explainer clicked";
export const TALKING_POINT_TOGGLED_RECURRING =
  "Talking point toggled recurring";
export const TEAM_REVIEW_ENABLED = "Team Reviews enabled";
export const TEAM_REVIEW_DISABLED = "Team Reviews disabled";
export const TOGGLED_NOTIFICATION = "Toggled all notifications";
export const TOGGLE_DEV_MODE = "Toggled development mode";
export const TOGGLE_EXPLORE_VIEW = "Toggle explore view";
export const TOGGLE_PROFILE_VIEW = "Toggle profile view";
export const TRIAL_BANNER_UPDATED = "Trial banner updated";
export const UNFOLD_MAPS_ALL_CARD = "All cards unfolded on aligned roadmap";
export const UNFOLD_MAPS_CARD = "Card unfolded on aligned roadmap";
export const UNHANDLED_ERROR = "Unhandled error";
export const UPDATE_GROUP = "Update group";
export const UPDATE_OBJECTIVE = "Update Objective";
export const UPDATE_PROGRESS = "Update progress";
export const UPDATE_STRATEGIC_PILLAR = "Update Strategic Pillar";
export const UPDATE_ULTIMATE_GOAL = "Update Ultimate Goal";
export const UPLOAD_PROFILE_PICTURE = "Upload profile picture";
export const USER_ARCHIVED = "Archive User";
export const USER_RESTORED = "Restore User";
export const USE_ALIGNED_OS_FILTER = "Use Aligned Objectives filter";
export const USE_ROADMAP_FILTER = "Use Roadmap filter";
export const USE_TIMELINE_NAVIGATION = "Use Strategic timeline navigation";
export const VIEW_CUSTOM_REPORTS = "View Custom Reports";
export const VIEW_KPI_DETAILS = "View KPI Details";
export const WEB_LOGIN = "Web Login";
export const NOT_FOUND = "404";
export const TEAM_REVIEW_EDIT_CONFLICT = "Team review edit conflict";

export const COMPANY_FEATURE_DISABLED_EVENTS: Partial<
  Record<keyof CompanyFeatures, string>
> = {
  flexibleObjectiveProgressDriver: "Flexible objective progress disabled",
  kudosEnabled: "Kudos disabled",
  oneOnOnesEnabled: "1:1 Meetings disabled",
  performanceReviewsEnabled: "Performance Reviews disabled",
  privateOkrs: "Private OKRs disabled",
  pulse: "Pulse disabled",
  reflections: "Review disabled",
  sharedGoals: "Shared goals disabled",
  stretchGoals: "Stretch goals disabled",
};

export const COMPANY_FEATURE_ENABLED_EVENTS: Partial<
  Record<keyof CompanyFeatures, string>
> = {
  flexibleObjectiveProgressDriver: "Flexible objective progress enabled",
  kudosEnabled: "Kudos enabled",
  oneOnOnesEnabled: "1:1 Meetings enabled",
  performanceReviewsEnabled: "Performance Reviews enabled",
  privateOkrs: "Private OKRs enabled",
  pulse: "Pulse enabled",
  reflections: "Review enabled",
  sharedGoals: "Shared goals enabled",
  stretchGoals: "Stretch goals enabled",
};
