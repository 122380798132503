import { Editor } from "@tiptap/react";
import classNames from "clsx";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { Button } from "common/buttons/Button/Button";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { ToolBarEmoji } from "common/inputs/TextEditor/ToolBarItem/Emoji/ToolBarEmoji";
import { ToolBarGif } from "common/inputs/TextEditor/ToolBarItem/Gif/ToolBarGif";
import { ToolBarImage } from "common/inputs/TextEditor/ToolBarItem/Image/ToolBarImage";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import ColorPicker from "../ToolBarItem/ColorPicker/ColorPicker";

interface Props {
  className?: string;
  editor: Editor | null;
  enableTasks?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  showActionsButtons?: boolean;
}

export const Toolbar = ({
  editor,
  className,
  showActionsButtons = false,
  onCancel,
  onSave,
  enableTasks,
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const inTeams = useInTeams();

  const addImage = useCallback(
    (url?: string) => {
      if (url && editor) {
        editor.chain().focus().setImage({ src: url }).run();
      }
    },
    [editor]
  );

  if (!editor) {
    return null;
  }

  return (
    <div
      className={classNames(
        "relative flex w-full flex-wrap items-center justify-between pb-1",
        className
      )}
    >
      <div className="flex flex-wrap space-x-1.5">
        <IconButton
          data-cy="EFHJ1wazrnaOhL0QB4b5v"
          className={classNames({
            "text-blue-500": editor.isActive("bold"),
          })}
          name="format_bold"
          onClick={() => editor.chain().focus().toggleBold().run()}
          size="2xl"
        />
        <IconButton
          data-cy="ZBWoVNFt409UiwsRlN0bw"
          className={classNames({
            "text-blue-500": editor.isActive("italic"),
          })}
          name="format_italic"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          size="2xl"
        />
        <IconButton
          data-cy="joWCw2bTJXwKoZpdDQBzd"
          className={classNames({
            "text-blue-500": editor.isActive("underline"),
          })}
          name="format_underlined"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          size="2xl"
        />
        <IconButton
          data-cy="joWCw2bTJXwKoZpdDQBzd"
          className={classNames({
            "text-blue-500": editor.isActive("strike"),
          })}
          name="format_strikethrough"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          size="2xl"
        />
        <ColorPicker editor={editor} />
        <IconButton
          data-cy="mqyEFa8dSBmQBdAqjuDdO"
          className={classNames({
            "text-blue-500": editor.isActive("orderedList"),
          })}
          name="format_list_numbered"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          size="2xl"
        />
        <IconButton
          data-cy="X5mmI5hI9NYWtNXWOF_nb"
          className={classNames({
            "text-blue-500": editor.isActive("bulletList"),
          })}
          name="format_list_bulleted"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          size="2xl"
        />
        <ToolBarImage onSubmit={addImage} />
        <ToolBarEmoji editor={editor} />
        {!inTeams && <ToolBarGif onSubmit={addImage} />}
        {enableTasks && (
          <IconButton
            data-cy="-TfqAi2rRzVBHDyWYGlxv"
            className={classNames("mx-1.5", {
              "is-active": editor.isActive("taskList"),
            })}
            name="add_task"
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            size="2xl"
          />
        )}
      </div>
      {showActionsButtons && (
        <div className="flex justify-end">
          <div className="mr-2">
            <Button
              data-cy="cancelCommentButton"
              onClick={onCancel}
              size="small"
              type="button"
              variant="ghost"
            >
              {intl.formatMessage({
                defaultMessage: "Cancel",
                id: "global:cancel",
              })}
            </Button>
          </div>
          <Button
            data-cy="addCommentButton"
            onClick={() => {
              onSave?.();
              onCancel?.();
            }}
            size="small"
            type="submit"
          >
            {intl.formatMessage({ defaultMessage: "Save", id: "global:save" })}
          </Button>
        </div>
      )}
    </div>
  );
};
