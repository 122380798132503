import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Entity } from "common/avatar";
import { For } from "common/controlFlow";
import { Icon } from "common/icons";
import { SelectFallback } from "common/inputs/Select/SelectFallback/SelectFallback";
import { useSelect } from "common/inputs/Select/useSelect";
import { useTimeframes } from "hooks/useTimeframes/useTimeframes";
import { twClass } from "utils/twClass";
import { groupTimeframes, searchTimeframes } from "./TimeframeSelect.helpers";
import { messages } from "./TimeframeSelect.messages";
import {
  OnSelectType,
  SelectRootProps,
  Timeframe,
} from "./TimeframeSelect.types";

export type TimeframeSelectProps<T extends boolean> = Omit<
  SelectRootProps,
  "children" | "onChange" | "value"
> & {
  availableTimeframes?: Timeframe[];
  button?: React.ReactNode;
  canSelectAll?: T;
  "data-cy"?: string;
  "data-testid"?: string;
  disabled?: boolean;
  hasError?: boolean;
  isInlineValue?: boolean;
  onSelect?: OnSelectType<T>;
  selected?: Timeframe;
  selectValueClassName?: string;
  showDropdownIcon?: boolean;
  showSelectValueAsText?: boolean;
};

export const TimeframeSelect = <T extends boolean = false>({
  availableTimeframes = [],
  button,
  canSelectAll = false as T,
  "data-cy": dataCy,
  "data-testid": dataTestId,
  selected,
  disabled,
  hasError,
  isInlineValue = true,
  showSelectValueAsText = false,
  showDropdownIcon = false,
  selectValueClassName,
  onSelect,
  ...selectRootProps
}: TimeframeSelectProps<T>): JSX.Element => {
  const intl = useIntl();
  const {
    activeTimeframes,
    pastTimeframes,
    futureTimeframes,
    timeframes: sortedTimeframes,
  } = useTimeframes(availableTimeframes);

  const {
    selected: selectedOption,
    handleSelect,
    handleSearch,
    Select,
    visibleOptions,
  } = useSelect({
    defaultItem: selected,
    options: sortedTimeframes,
    searchFn: searchTimeframes,
  });

  const handleChange = (timeframe: Timeframe | null) => {
    onSelect?.(timeframe as T extends true ? Timeframe | null : Timeframe);
    handleSelect(timeframe);
  };

  const groupedTimeframes = useMemo(
    () =>
      groupTimeframes(
        [
          { key: "activeTimeframes", timeframes: activeTimeframes },
          { key: "futureTimeframes", timeframes: futureTimeframes },
          { key: "pastTimeframes", timeframes: pastTimeframes },
        ],
        visibleOptions,
        intl
      ),
    [activeTimeframes, futureTimeframes, pastTimeframes, visibleOptions, intl]
  );

  const dropdownWidth = isInlineValue ? "190px" : undefined;
  const areNoTimeframesPresent = isEmpty(groupedTimeframes) && !canSelectAll;

  const ValueComponent = isInlineValue ? Select.InlineValue : Select.Value;
  const valueComponentProps = isInlineValue ? { showSelectValueAsText } : {};

  const renderValueComponent = () => (
    <ValueComponent
      {...valueComponentProps}
      className={twClass(selectValueClassName, {
        "flex items-center justify-end": canSelectAll || showDropdownIcon,
        "m-0 p-0 border-0": showSelectValueAsText,
        "text-red-500": isInlineValue && hasError,
      })}
    >
      {selectedOption ? (
        <Entity name={selectedOption.name} />
      ) : (
        <FormattedMessage
          {...messages[canSelectAll ? "allTimeframes" : "placeholder"]}
        />
      )}
      {showDropdownIcon && (
        <Icon className="cursor-pointer" name="keyboard_arrow_down" size="lg" />
      )}
    </ValueComponent>
  );

  return (
    <Select.Root
      {...selectRootProps}
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={handleChange}
      value={selectedOption}
      disabled={disabled}
    >
      <Select.Trigger>
        <div className="flex items-center">
          {button || renderValueComponent()}
        </div>
      </Select.Trigger>
      <Select.Dropdown
        className="divide-y divide-slate-300"
        dropdownWidth={dropdownWidth}
      >
        <Select.Searchbox onChange={handleSearch} />
        <Select.Options>
          {canSelectAll && (
            <>
              <Select.Option
                className={twClass(
                  "w-full flex items-center justify-between",
                  "cursor-default hover:cursor-pointer",
                  "px-2 py-1 text-slate-800 "
                )}
                value={null}
              >
                <Entity name={intl.formatMessage(messages.allTimeframes)} />
              </Select.Option>
              <hr />
            </>
          )}
          {groupedTimeframes.map(({ label, timeframes }, index) => (
            <React.Fragment key={label}>
              {index !== 0 && <hr />}
              <div className="text-sm text-slate-500 px-2.5 py-1.5">
                {label}
              </div>
              <For each={timeframes} fallback={<SelectFallback />}>
                {(option) => (
                  <Select.Option
                    key={option.id}
                    className={twClass(
                      "w-full flex items-center justify-between px-2 py-1",
                      "text-slate-800 cursor-default"
                    )}
                    value={option}
                  >
                    <Entity name={option.name} />
                  </Select.Option>
                )}
              </For>
            </React.Fragment>
          ))}
          {areNoTimeframesPresent && (
            <p className="text-sm text-slate-500 px-2.5 py-0.5">
              {intl.formatMessage(messages.noTimeframes)}
            </p>
          )}
        </Select.Options>
      </Select.Dropdown>
    </Select.Root>
  );
};
