import { SelectBaseItem } from "./Select.types";
import { SelectDropdown } from "./SelectDropdown/SelectDropdown";
import { SelectInlineValue } from "./SelectInlineValue/SelectInlineValue";
import { SelectOption } from "./SelectOption/SelectOption";
import { SelectOptions } from "./SelectOptions/SelectOptions";
import { SelectRoot } from "./SelectRoot/SelectRoot";
import { SelectSearchbox } from "./SelectSearchbox/SelectSearchbox";
import { SelectTrigger } from "./SelectTrigger/SelectTrigger";
import { SelectValue } from "./SelectValue/SelectValue";

/**
 * <p> this is a generic function that returns a Select component, built with headless ui under the hood. you can use it with manual state or the provided useSelect hook. </p>
 *
 * <p> 1. you can use the {@link useSelect} hook and pass the returned values as props to the components (see {@link SelectWithHookDefault}). </p>
 *
 * <p> - if you want to customize how you map or filter items, you can pass custom functions to the hook, see {@link SelectWithHookCustom} story. </p>
 *
 * <p> 2. or you can handle select state and search manually (see {@link SelectNoHook} story). this is also the recommended option for async version,
 * i.e. when search and filtering happens server-side (see {@link SelectAsync} story). </p>
 *
 * @see https://headlessui.com/react/listbox
 */
export const makeSelect = <Item extends SelectBaseItem>() =>
  ({
    /**
     * Select's dropdown.
     */
    Dropdown: SelectDropdown,

    /**
     * An inline select div that can be put as children for `Select.Trigger`.
     *
     * @example
     * ```tsx
     * <Select.Trigger>
     *     <Select.Value>{selected.label}</Select.Value>
     * </Select.Trigger>
     * ```
     *
     */
    InlineValue: SelectInlineValue,

    /**
     * Single clickable option.
     */
    Option: SelectOption<Item | null>,

    /**
     * Options container.
     */
    Options: SelectOptions,

    /**
     * Root object.
     */
    Root: SelectRoot<Item>,

    /**
     * A searchbox that can be added inside `Select.Dropdown`.
     *
     * @note This is an uncontrolled components (it handles its own state).
     * @example
     * ```tsx
     * <Select.Dropdown className="divide-y divide-slate-300">
     *    <Select.Searchbox onChange={(query) => fetch(query)} />
     *    ...
     * </Select.Dropdown>
     * ```
     */
    Searchbox: SelectSearchbox,

    /**
     * Passes `onClick` to its direct children to open the select dropdown.
     *
     * @note You have to make sure that whatever component is passed as children:
     *  - Is wrapped in a `<Select.Value>`
     *  - Forwards its ref or is a class component
     *  - Accepts an `onClick` prop
     */
    Trigger: SelectTrigger,

    /**
     * A select like input that can be put as children for `Select.Trigger`.
     *
     * @example
     * ```tsx
     * <Select.Trigger>
     *     <Select.Value>{selected.label}</Select.Value>
     * </Select.Trigger>
     * ```
     *
     */
    Value: SelectValue,
  }) as const;
