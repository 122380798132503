import {
  PerdooApiKeyResultStatusChoices,
  ResultDefaultFragment,
  ResultsFieldsWithChildrenFragment,
  useUpsertResultIndexMutationMutation,
} from "@graphql";
import { orderBy } from "lodash";
import React, { useContext } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { ResultsFilterContext } from "common/result/ResultsFilterContext/ResultsFilterContext";
import { REORDER } from "constants/tracking";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { track } from "utils/tracker";
import {
  ResultWrapper,
  ResultWrapperProps,
} from "./ResultWrapper/ResultWrapper";
import {
  InitiativesListElementType,
  InitiativesListType,
} from "./SortableResultItem.types";

const SubInitiativesList = SortableContainer<InitiativesListType>(
  ({ initiatives, showEdges }: InitiativesListType) => {
    const SubInitiativesListElement =
      SortableElement<InitiativesListElementType>(
        ({ result, showEdges }: ResultWrapperProps) => (
          <ResultWrapper result={result} showEdges={showEdges} />
        )
      );

    return (
      <div data-cy="initiativesList" className="flex-col space-y-2.5">
        {initiatives.map((initiative: ResultDefaultFragment, idx) => (
          <SubInitiativesListElement
            key={initiative.id}
            index={idx}
            result={initiative}
            showEdges={showEdges}
          />
        ))}
      </div>
    );
  }
);

export const SortableResultItem = SortableElement<{
  result: ResultsFieldsWithChildrenFragment;
}>(({ result }: { result: ResultsFieldsWithChildrenFragment }) => {
  return (
    <>
      <ResultWrapper key={result.id} result={result} />
      <SubInitiatives result={result} />
    </>
  );
});

export const SubInitiatives = ({
  result,
  showEdges,
}: {
  result: ResultsFieldsWithChildrenFragment;
  showEdges?: boolean;
}) => {
  const filters = useContext(ResultsFilterContext);
  const [updateResultIndex] = useUpsertResultIndexMutationMutation();
  let initiatives = mapEdges(result.children.edges);

  if (filters.isOutdated) {
    initiatives = initiatives.filter((initiative) => initiative.isOutdated);
  }
  if (isSet(filters.archived)) {
    initiatives = initiatives.filter((i) => !i.archived);
  }
  if (filters.excludeAccomplished) {
    initiatives = initiatives.filter(
      (i) => i.status !== PerdooApiKeyResultStatusChoices.Accomplished
    );
  }

  const orderedInits = orderBy(initiatives, ["index"], ["asc"]);
  const onSortEnd = async ({
    oldIndex,
    newIndex,
  }: {
    newIndex: number;
    oldIndex: number;
  }) => {
    const result = orderedInits[oldIndex];
    await updateResultIndex({
      variables: {
        input: {
          id: result?.id,
          index: newIndex,
        },
      },
    });

    track(REORDER, {
      okr_type: "nested result",
    });
  };

  return (
    <SubInitiativesList
      showEdges={showEdges}
      distance={3}
      helperClass="react-sortable-dragging"
      initiatives={orderedInits}
      onSortEnd={onSortEnd}
      useDragHandle
    />
  );
};
