import { PerdooApiIntegrationApplicationChoices } from "@graphql";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { startCase } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button } from "common/buttons";
import { FormAsyncSelectGql } from "common/form/FormAsyncSelectGql/FormAsyncSelectGql";
import { FormInput } from "common/form/FormInput/FormInput";
import { FormMetricSelect } from "common/form/FormMetricSelect/FormMetricSelect";
import { FormTextArea } from "common/form/FormTextArea/FormTextArea";
import { ObjectiveField } from "common/form/ObjectiveField/ObjectiveField";
import { UserField } from "common/form/UserField";
import { WithHelper } from "common/form/WithHelper/WithHelper";
import { GoalSelectorButton } from "common/goal/GoalSelectorButton/GoalSelectorButton";
import {
  CategorySelect,
  CategorySelectItem,
} from "common/inputs/CategorySelect/CategorySelect";
import { useHelpCenterContent } from "common/layout/HelpCenter/hooks/useHelpCenterContent";
import { ResultTypesInfoView } from "common/layout/HelpCenter/ResultTypesInfoView/ResultTypesInfoView";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { getMetricSymbol } from "constants/metric";
import { objectTypes } from "constants/objects";
import { resultTypes } from "constants/resultTypes";
import { allRolesButViewonly } from "constants/roles";
import { SELECT_EXAMPLE_GOAL } from "constants/tracking";
import { useFormSubmitOnShortcut } from "hooks/useFormSubmitOnShortcut/useFormSubmitOnShortcut";
import { useSampleGoals } from "hooks/useSampleGoals/useSampleGoals";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { getRandomMessage } from "utils/getRandomMessage";
import { track } from "utils/tracker";
import {
  ResultFormContextProvider,
  ResultFormIntegration,
} from "../utils/useResultFormContext/useResultFormContext";
import { AdvancedSection } from "./AdvancedSection/AdvancedSection";
import { IntegrationLinkWarning } from "./IntegrationLinkWarning/IntegrationLinkWarning";
import { helper } from "./ResultForm.helper";
import { messages } from "./ResultForm.messages";
import { namePlaceholders } from "./ResultForm.placeholders";
import { schema } from "./ResultForm.schema";
import { ResultFormProps, ResultFormValues } from "./ResultForm.types";
import { ResultFormWarnings } from "./ResultFormWarnings/ResultFormWarnings";
import { ResultTypeField } from "./ResultTypeField/ResultTypeField";

export const ResultForm = ({
  onSubmit,
  initialValues,
  isEdit,
  requestAddAnother,
  onObjectiveChange,
  sampleObjective,
}: ResultFormProps): JSX.Element => {
  const intl = useIntl();

  const dropdownRef = useRef<Dropdown>(null);
  const { formRef } = useFormSubmitOnShortcut<ResultFormValues>();

  const [integration, setIntegration] = useState<ResultFormIntegration | null>(
    null
  );
  const isJiraSelected =
    integration?.application === PerdooApiIntegrationApplicationChoices.Jira;
  const isAsanaSelected =
    integration?.application === PerdooApiIntegrationApplicationChoices.Asana;

  useHelpCenterContent({ element: <ResultTypesInfoView />, type: "results" });

  const [selectedSampleResult, setSelectedSampleResult] = useState<
    CategorySelectItem | undefined
  >(undefined);

  const groupName = sampleObjective?.owner;
  const [sampleGoalsByGroup, sampleGoalsFound] = useSampleGoals(
    "result",
    groupName,
    sampleObjective
  );

  const placeholders = useMemo(
    () => ({
      name: getRandomMessage(namePlaceholders),
    }),
    []
  );

  const handleSubmit = (
    values: ResultFormValues,
    actions: FormikHelpers<ResultFormValues>
  ) => {
    onSubmit(values, actions, selectedSampleResult);
  };

  const sampleGoalSelected = (
    setFieldValue: (field: string, value: unknown) => void,
    result: CategorySelectItem
  ) => {
    setSelectedSampleResult(result);
    track(SELECT_EXAMPLE_GOAL, {
      title: result.name,
      type: objectTypes.keyresult,
    });

    setFieldValue("name", result.name);
    setFieldValue("startValue", result.startValue);
    setFieldValue("endValue", result.endValue);
    setFieldValue("type", result.type);
    dropdownRef.current?.close();
  };
  return (
    <ResultFormContextProvider
      value={{ integration, isAsanaSelected, isJiraSelected, setIntegration }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema(integration?.application ?? null)}
        validateOnBlur={false}
        validateOnChange={false}
        innerRef={formRef}
      >
        {(formikProps) => {
          const { values, isSubmitting, submitForm, setFieldValue } =
            formikProps;

          const isFixedPercentage = isAsanaSelected || isJiraSelected;

          const goalSelector = (
            <Dropdown
              ref={dropdownRef}
              button={<GoalSelectorButton type={objectTypes.keyresult} />}
              buttonOpen={
                <GoalSelectorButton
                  className="border border-blue-500"
                  type={objectTypes.keyresult}
                />
              }
              forcePosition="bottom-left"
            >
              <CategorySelect
                categories={sampleGoalsByGroup}
                className="max-h-96 w-96"
                icon={{ name: "flag", type: "outlined" }}
                onSelect={(result) => {
                  sampleGoalSelected(setFieldValue, result);
                }}
                selectFirst={sampleGoalsFound || sampleObjective}
                transform={startCase}
              />
            </Dropdown>
          );

          return (
            <Form data-cy="resultForm">
              <WithHelper data-testid="name" helper={helper.name}>
                <Field name="name">
                  {({ field, form }: FieldProps) => (
                    <FormInput
                      data-testid="resultName"
                      addOn={goalSelector}
                      addOnSeparate
                      autoFocus
                      field={field}
                      form={form}
                      label={intl.formatMessage(messages.nameLabel)}
                      name="name"
                      placeholder={intl.formatMessage(placeholders.name)}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper data-testid="description">
                <Field name="description">
                  {({ field, form }: FieldProps) => (
                    <FormTextArea
                      data-testid="resultDescription"
                      field={field}
                      form={form}
                      label={intl.formatMessage(messages.descriptionLabel)}
                      optional
                      placeholder={intl.formatMessage(
                        messages.descriptionPlaceholder
                      )}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper data-testid="lead" helper={helper.lead}>
                <UserField
                  variables={{
                    roleIn: allRolesButViewonly.join(),
                  }}
                  data-cy="fieldLead"
                  data-testid="fieldLead"
                  label={intl.formatMessage(messages.leadLabel)}
                  name="lead"
                />
              </WithHelper>

              <WithHelper data-testid="objective" helper={helper.objective}>
                <ObjectiveField
                  data-cy="objective"
                  name="objective"
                  label={intl.formatMessage(messages.objectiveLabel)}
                  onChange={onObjectiveChange}
                />
              </WithHelper>

              <WithHelper data-testid="metricUnitField">
                <Field name="metricUnit">
                  {({ field }: FieldProps) => (
                    <FormMetricSelect
                      data-cy="metricUnit"
                      data-testid="metricUnit"
                      disabled={isFixedPercentage}
                      field={field}
                      label={intl.formatMessage(messages.metricUnit)}
                    />
                  )}
                </Field>
              </WithHelper>

              <WithHelper data-testid="valueRange">
                <div className="flex items-center space-x-4">
                  <Field name="startValue">
                    {({ field, form }: FieldProps) => (
                      <div className="grow">
                        <FormInput
                          addOn={
                            values.metricUnit
                              ? getMetricSymbol(values.metricUnit)
                              : ""
                          }
                          data-cy="startValue"
                          data-testid="startValue"
                          disabled={isFixedPercentage}
                          field={field}
                          form={form}
                          isLeft
                          label={intl.formatMessage(messages.startLabel)}
                          metricUnit={
                            values.metricUnit &&
                            getMetricSymbol(values.metricUnit)
                          }
                          placeholder={intl.formatMessage(
                            messages.startPlaceholder
                          )}
                          type="number"
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="endValue">
                    {({ field, form }: FieldProps) => (
                      <div className="grow">
                        <FormInput
                          addOn={
                            values.metricUnit
                              ? getMetricSymbol(values.metricUnit)
                              : ""
                          }
                          data-cy="endValue"
                          data-testid="endValue"
                          disabled={isFixedPercentage}
                          field={field}
                          form={form}
                          isLeft
                          label={intl.formatMessage(messages.endLabel)}
                          metricUnit={
                            values.metricUnit &&
                            getMetricSymbol(values.metricUnit)
                          }
                          placeholder={intl.formatMessage(
                            messages.endPlaceholder
                          )}
                          type="number"
                        />
                      </div>
                    )}
                  </Field>
                </div>
                <IntegrationLinkWarning
                  integration={integration?.application}
                />
              </WithHelper>

              <WithHelper data-testid="type">
                <ResultTypeField
                  data-cy="resultType"
                  form={formikProps}
                  name="type"
                />
                <ResultFormWarnings values={values} />
              </WithHelper>

              {values.type === resultTypes.initiative && (
                <WithHelper data-testid="parent" helper={helper.keyResult}>
                  <Field name="parent">
                    {({ field, form }: FieldProps) => (
                      <FormAsyncSelectGql
                        entityType={objectTypes.keyresult}
                        field={field}
                        filters={{
                          resultsObjectiveIn: values.objective,
                          resultsType: resultTypes.keyResult,
                        }}
                        form={form}
                        label={intl.formatMessage(messages.keyResultLabel)}
                        optional
                      />
                    )}
                  </Field>
                </WithHelper>
              )}
              <AdvancedSection />
              <ModalFooter>
                <Button
                  data-testid="submitResult"
                  data-cy="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={submitForm}
                  type="submit"
                >
                  {intl.formatMessage({
                    defaultMessage: "Save",
                    id: "global:save",
                  })}
                </Button>
                {!isEdit && (
                  <Button
                    data-testid="addAnotherResult"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => {
                      requestAddAnother?.();
                      submitForm();
                    }}
                    type="submit"
                    variant="ghost"
                  >
                    {intl.formatMessage({
                      defaultMessage: "Add another",
                      id: "global:add:another",
                    })}
                  </Button>
                )}
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </ResultFormContextProvider>
  );
};
