import { useField } from "formik";
import React from "react";
import { Show } from "common/controlFlow";
import { Label } from "common/inputs";
import {
  TimeframeSelect,
  TimeframeSelectProps,
} from "common/select/TimeframeSelect/TimeframeSelect";
import { Timeframe } from "common/select/TimeframeSelect/TimeframeSelect.types";
import { useSelectedTimeframe } from "hooks/useSelectedTimeframe/useSelectedTimeframe";
import { isSet } from "utils/isSet";

type Props = Omit<TimeframeSelectProps<false>, "defaultItem" | "timeframes"> & {
  label?: string | React.ReactNode;
  name: string;
  wrapperClassName?: string;
};

export const TimeframeField = ({
  name,
  onSelect,
  label,
  wrapperClassName,
  ...otherSelectProps
}: Props): JSX.Element | null => {
  const [field, , helpers] = useField(name);

  const { timeframe } = useSelectedTimeframe(field.value);

  const handleChange = (timeframe: Timeframe | null) => {
    if (!timeframe) return;

    helpers.setValue(timeframe.id);
    if (onSelect) onSelect(timeframe);
  };

  return (
    <div className={wrapperClassName}>
      <Show when={isSet(label)}>
        <Label htmlFor="timeframe">{label}</Label>
      </Show>
      <TimeframeSelect
        {...otherSelectProps}
        isInlineValue={false}
        selected={timeframe ?? undefined}
        onSelect={handleChange}
      />
    </div>
  );
};
