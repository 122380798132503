import { defineMessages } from "react-intl";

export const messages = defineMessages({
  back: {
    defaultMessage: "Back",
    id: "user:profile:checkIn:back",
  },
  errorEditingCheckIn: {
    defaultMessage: "Something went wrong when adding the kpi board",
    id: "user:profile:checkIn:errorEditingCheckIn",
  },
  pulseAnswer: {
    defaultMessage: "Answer",
    id: "userCheckInDetails:pulse:answer",
  },
  pulseEmptyState: {
    defaultMessage: "No note left",
    id: "userCheckInDetails:pulse:emptyState",
  },
  reflectionsChanged: {
    defaultMessage: "Review changed",
    id: "9ZuysO",
  },
});
