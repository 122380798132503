import { Color } from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from '@tiptap/extension-strike'
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import classNames from "clsx";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { Icon } from "common/icons";
import { InlineEmoji } from "common/inputs/TextEditor/ToolBarItem/SlackEmojis/InlineEmoji";
import { useKeyTrigger } from "hooks/useKeyTrigger/useKeyTrigger";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { isSet } from "utils/isSet";
import { twClass } from "utils/twClass";
import { stripHTML } from "utils/utils";
import { Toolbar } from "./ToolBar/ToolBar";
import { Mention } from "./ToolBarItem/Mentions/Mention";
import { suggestion } from "./ToolBarItem/Mentions/suggestion";
import { suggestion as emojiSuggestions } from "./ToolBarItem/SlackEmojis/suggestion";

export type TextEditorVariant = "borderless" | "normal";

export interface TextEditorProps {
  autoFocus?: boolean;
  className?: string;
  clearOnCancel?: boolean;
  clickOutsideWhitelist?: string[];
  containerClassName?: string;
  "data-cy"?: string;
  "data-testid"?: string;
  editorClassName?: string;
  editWarning?: ReactNode;
  enableTasks?: boolean;
  label?: string;
  labelClassName?: string;
  minimizedView?: boolean;
  onBackgroundSave?: (value: string) => void;
  onCancel?: () => void;
  onChange?: (value: string) => void;
  onEditorBlur?: (value: string) => void;
  onEditorFocus?: (value: string) => void;
  onSubmit?: () => void;
  optional?: boolean;
  persistTemplate?: boolean;
  placeholder?: string;
  plain?: boolean;
  required?: boolean;
  showActionsButtons?: boolean;
  template?: string;
  text: string;
  variant?: TextEditorVariant;
}

export const TextEditor = React.memo(
  ({
    "data-cy": dataCy,
    "data-testid": dataTestId,
    autoFocus = false,
    className,
    clearOnCancel = true,
    containerClassName,
    /** clicking on elements listed here will not trigger a blur event */
    clickOutsideWhitelist = [],
    editWarning,
    editorClassName,
    enableTasks = false,
    label,
    minimizedView = false,
    onBackgroundSave = () => {},
    onCancel,
    onChange,
    onEditorBlur,
    onEditorFocus,
    onSubmit,
    optional = false,
    placeholder = "",
    persistTemplate = false,
    plain = false,
    required,
    showActionsButtons = false,
    template = "",
    text,
    labelClassName,
    variant = "normal",
  }: TextEditorProps): JSX.Element => {
    const ref = useRef<HTMLDivElement>(null);
    const [focused, setFocusState] = useState(autoFocus);

    const debouncedBackgroundSave = useCallback(
      debounce(onBackgroundSave, 5_000),
      []
    );

    const autofocus = autoFocus ? "end" : false;
    const editor = useEditor({
      autofocus,
      content: text,
      editorProps: {
        attributes: {
          ...(isSet(dataTestId) ? { "data-testid": dataTestId } : {}),
          ...(isSet(dataCy) ? { "data-cy": dataCy } : {}),
        },
      },
      extensions: [
        StarterKit,
        Underline,
        Image,
        Color,
        Strike,
        TextStyle,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Mention.configure({
          HTMLAttributes: {
            class: "mention cursor-pointer",
          },
          suggestion,
        }),
        InlineEmoji.configure({
          HTMLAttributes: {
            class: "inlineEmoji",
          },
          suggestion: emojiSuggestions,
        }),
        Placeholder.configure({
          emptyEditorClass: classNames("is-editor-empty", {
            "dark-slate-500-placeholder": plain,
          }),
          placeholder,
        }),
        Link.configure({
          autolink: false,
          HTMLAttributes: {
            class: "cursor-pointer",
          },
          openOnClick: true,
        }),
      ],
      onFocus: ({ editor }) => {
        setFocusState(true);
        if (isEmpty(stripHTML(editor.getHTML())) && !isEmpty(template)) {
          editor.commands.setContent(template);
        }
        onEditorFocus?.(editor.getHTML());
      },
      onUpdate({ editor }) {
        const editorValue = editor.getHTML();
        onChange?.(editorValue);
        debouncedBackgroundSave(editorValue);
      },
    });

    const handleFocus = () => {
      if (!focused) {
        editor?.commands.focus("end");
        setFocusState(true);
      }
    };

    const handleBlur = () => {
      setFocusState(false);
      if (isNil(editor)) return;

      if (template === editor.getHTML() && !persistTemplate) {
        editor.commands.clearContent();
      }
      onEditorBlur?.(editor.getHTML());
    };

    const handleClickOutside = () => {
      // prevents accidental trigger when the page has multiple text editors.
      if (focused) {
        handleBlur();
      }
    };

    useOutsideAlerter(
      ref,
      handleClickOutside,
      [
        "#text-editor-tools-root",
        "[data-mentions-list]",
        // Toolbar panels
        "#emoji-picker",
        "#color-picker",
        "[data-testid=emojiList]",
        "#image-picker",
        "#gif-picker",
      ].concat(clickOutsideWhitelist)
    );

    useKeyTrigger("Escape", () => {
      editor?.commands.blur();
      onCancel?.();
    });

    useEffect(() => {
      if (!editor) return;
      const { from, to } = editor.state.selection;
      if (editor.can().setContent(text)) {
        editor.commands.setContent(text, false, {
          preserveWhitespace: "full",
        });
        editor.commands.setTextSelection({ from, to });
      }
    }, [text, editor, onChange]);

    const handleCancel = () => {
      setFocusState(false);
      if (clearOnCancel) {
        editor?.commands.clearContent();
      } else {
        editor?.commands.setContent(text);
      }
      onCancel?.();
    };

    return (
      <div
        className={twClass("space-y-2", containerClassName, "flex flex-col")}
        data-cy="textEditorContainer"
      >
        <div className="flex grow flex-col">
          <div className="mb-1 flex justify-between" data-cy="textEditorHeader">
            {label && (
              <div className={twClass("text-sm", labelClassName)}>{label}</div>
            )}
            {optional && (
              <div className="text-sm text-slate-500">
                <FormattedMessage
                  defaultMessage="Optional"
                  id="global:form:optional"
                />
              </div>
            )}
          </div>
          <div
            ref={ref}
            className={twClass(
              "flex w-full grow flex-col space-y-2 break-word",
              {
                "border-none bg-transparent":
                  plain && !focused && variant !== "borderless",
                "px-2 py-1 border border-solid rounded bg-white":
                  variant !== "borderless",
              },
              className,
              {
                "-ml-px -mt-px border-2 border-blue-500 hover:border-blue-500":
                  focused && variant !== "borderless",
                "ml-0 mt-0": plain && focused && variant !== "borderless",
              }
            )}
            data-cy="textEditor"
            data-testid="textEditor"
            id="text-editor"
            onClick={handleFocus}
          >
            <div
              className={twClass(
                "modal-scrollbar modal-scrollbar-thumb w-full grow overflow-y-auto",
                editorClassName
              )}
            >
              <EditorContent className="h-[90%] w-full " editor={editor} />
            </div>
            <Show when={focused || !minimizedView}>
              <Toolbar
                className={twClass({ invisible: !focused })}
                editor={editor}
                enableTasks={enableTasks}
                onCancel={handleCancel}
                onSave={onSubmit}
                showActionsButtons={showActionsButtons}
              />
            </Show>
          </div>
        </div>
        {isSet(editWarning) && focused && (
          <div className="flex grow-0 items-center space-x-2 text-slate-500">
            <Icon name="error_outline" size="xl" />
            <div>{editWarning}</div>
          </div>
        )}
        <Show when={required}>
          <div className="text-red-500">
            <FormattedMessage defaultMessage="Required" id="global:required" />
          </div>
        </Show>
      </div>
    );
  },
  (prev, current) => {
    // TODO: Probably remove.
    // Why is this needed? This means that if we change most props (e.g.
    // showActionButtons) it won't re-render.
    return (
      isEqual(current.text, prev.text) &&
      isEqual(current.className, prev.className)
    );
  }
);

TextEditor.displayName = "TextEditor";
