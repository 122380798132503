import {
  MakeOptional,
  UserAvatarFragment,
  UserDefaultFragment,
  UserJobFragment,
  UserNameFragment,
  UserStatusFragment,
} from "@graphql";
import { Form, Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { ShowFeature } from "common/access/ShowFeature";
import { useFeatureAccess } from "common/access/useFeatureAccess";
import { Button } from "common/buttons";
import { Show } from "common/controlFlow";
import { InputField } from "common/fields/InputField/InputField";
import { FormControl } from "common/form/FormControl/FormControl";
import { GroupMultiField } from "common/form/GroupMultiField/GroupMultiField";
import { UserField } from "common/form/UserField";
import { UserLanguageField } from "common/form/UserLanguageField";
import { UserMultiField } from "common/form/UserMultiField/UserMultiField";
import { UserRoleField } from "common/form/UserRoleField";
import { YesNoField } from "common/form/YesNoField";
import { ModalFooter } from "common/overlay/Modal/ModalFooter/ModalFooter";
import { messages } from "common/user/UserForm/UserForm.messages";
import { linkToPrivacy, linkToTerms } from "constants/links";
import { roles, UserRole } from "constants/roles";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useFormSubmitOnShortcut } from "hooks/useFormSubmitOnShortcut/useFormSubmitOnShortcut";
import { usePasswordConfirmation } from "hooks/usePasswordConfirmation/usePasswordConfirmation";
import { usePermissions } from "hooks/usePermissions/usePermissions";
import { isSet } from "utils/isSet";
import { hasUserAccess, isRoleSuperior } from "utils/permissions";
import { ConfirmPasswordModal } from "./ConfirmPasswordModal/ConfirmPasswordModal";

const schema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Required"),
  invite: Yup.boolean(),
  role: Yup.string().required("Please select a role"),
});

type User = Omit<
  UserDefaultFragment & UserNameFragment & UserJobFragment,
  "fullName" | "__typename"
>;

type UserFormDetails = MakeOptional<User, "id">;

type Manager = UserDefaultFragment & UserAvatarFragment & UserStatusFragment;

export type UserFormValues = UserFormDetails & {
  directReports?: string[];
  expireInvite?: boolean;
  groups: string[];
  invite?: boolean;
  language?: string | null;
  manager?: string | null;
  password?: string;
  role: UserRole;
};

type Props = {
  emailInfo?: boolean;
  initialValues: UserFormValues;
  isEdit?: boolean;
  isPersonalSettings?: boolean;
  manager?: Manager | null;
  onSubmit: (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>
  ) => void;
  setAddAnother?: (addAnother: boolean) => void;
};

export const UserForm = ({
  emailInfo,
  initialValues,
  isEdit,
  isPersonalSettings,
  manager,
  onSubmit,
  setAddAnother,
}: Props): JSX.Element => {
  const intl = useIntl();
  const currentUser = useCurrentUser();
  const company = useCompany();
  const { canAddDirectReports } = usePermissions();
  const isCurrentUser = currentUser?.id === initialValues.id;
  const strictSsoDisabled = company.ssoconfig?.allowEmailPwAuth;

  const {
    checkPassword,
    showConfirmPassword,
    toggleShowConfirmPassword,
    setShowConfirmPassword,
  } = usePasswordConfirmation();

  const { formRef } = useFormSubmitOnShortcut<UserFormValues>();

  const { show: showRole } = useFeatureAccess("DROPDOWN_USER_ROLE");

  const excludeUserIds = initialValues.id ? [initialValues.id] : [];
  const canEditRole =
    (hasUserAccess(currentUser, roles.admin) &&
      isRoleSuperior(
        currentUser,
        showRole ? roles.standard : roles.superAdmin
      )) ||
    hasUserAccess(currentUser, roles.superAdmin);
  const canEditGroups = hasUserAccess(currentUser, roles.admin);

  const getAddAnotherHandler =
    (submitForm: FormikHelpers<unknown>["submitForm"]) => () => {
      setAddAnother?.(true);
      submitForm();
    };

  const handleOnSubmit = async (
    values: UserFormValues,
    actions: FormikHelpers<UserFormValues>
  ) => {
    if (!isCurrentUser || (isSet(strictSsoDisabled) && !strictSsoDisabled)) {
      await onSubmit(values, actions);
      return;
    }
    const initialEmail = initialValues["email"];
    const isNewUser = isEmpty(initialEmail);
    const emailChanged = initialEmail !== values["email"];
    const passwordNotSet = !isSet(values.password);

    if (emailChanged && !isNewUser && passwordNotSet) {
      setShowConfirmPassword(true);
      return;
    }

    let hasAccess = !emailChanged;
    if (!isNewUser && values.password) {
      hasAccess = await checkPassword(values.password);
    }

    if (hasAccess || isNewUser) {
      await onSubmit(values, actions);
    }

    actions.setFieldValue("password", undefined);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      validationSchema={schema}
      innerRef={formRef}
    >
      {({ values, isSubmitting, submitForm, setFieldValue }) => (
        <Form>
          <InputField
            infoElement={
              emailInfo && (
                <div>{intl.formatMessage(messages.emailChangeInfo)}</div>
              )
            }
            label={intl.formatMessage(messages.emailLabel)}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            type="email"
            name="email"
            id="email"
          />
          <div className="grid grid-cols-2 gap-2">
            <InputField
              data-cy="userFirstName"
              label={intl.formatMessage({
                defaultMessage: "First name",
                id: "settings:user:firstNameLabel",
              })}
              optional
              placeholder={intl.formatMessage({
                defaultMessage: "First name",
                id: "settings:user:firstNamePlaceholder",
              })}
              name="firstName"
              id="firstName"
            />

            <InputField
              data-cy="userLastName"
              label={intl.formatMessage({
                defaultMessage: "Last name",
                id: "settings:user:lastNameLabel",
              })}
              optional
              placeholder={intl.formatMessage({
                defaultMessage: "Last name",
                id: "settings:user:lastNamePlaceholder",
              })}
              id="lastName"
              name="lastName"
            />

            <ShowFeature
              fallback={
                <InputField
                  name="jobPosition"
                  data-cy="userJobPosition"
                  label={intl.formatMessage({
                    defaultMessage: "Job title",
                    id: "settings:user:jobPositionLabel",
                  })}
                  optional
                  placeholder={intl.formatMessage({
                    defaultMessage: "Job title",
                    id: "settings:user:jobPositionPlaceholder",
                  })}
                />
              }
              feature="DROPDOWN_USER_ROLE"
            >
              <InputField
                name="jobPosition"
                data-cy="userJobPosition"
                label={intl.formatMessage({
                  defaultMessage: "Job title",
                  id: "settings:user:jobPositionLabel",
                })}
                optional
                placeholder={intl.formatMessage({
                  defaultMessage: "Job title",
                  id: "settings:user:jobPositionPlaceholder",
                })}
                id="jobPosition"
              />
              <FormControl
                infoElement={
                  <div>
                    <FormattedMessage
                      defaultMessage="Only admins and superadmins can edit new users and groups."
                      id="+YaWs6"
                    />
                    &nbsp;
                    <a
                      href="https://support.perdoo.com/en/articles/1588568-roles-rights"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <FormattedMessage
                        defaultMessage="Read more about roles here."
                        id="settings:user:roleInfoMore"
                      />
                    </a>
                  </div>
                }
                label={intl.formatMessage({
                  defaultMessage: "Role",
                  id: "settings:user:roleLabel",
                })}
              >
                <UserRoleField disabled={!canEditRole} name="role" />
              </FormControl>
            </ShowFeature>
          </div>
          <GroupMultiField
            data-cy="userGroups"
            disabled={!canEditGroups}
            label={intl.formatMessage(messages.teamsLabel)}
            name="groups"
            optional
          />
          <Show when={isPersonalSettings}>
            <FormControl label={intl.formatMessage(messages.languageLabel)}>
              <UserLanguageField data-cy="userLanguage" name="language" />
            </FormControl>
          </Show>
          <Show when={!isEdit}>
            <FormControl label={intl.formatMessage(messages.sendInvites)}>
              <YesNoField name="invite" />
            </FormControl>
            <Show when={values.invite === true}>
              <FormControl label={intl.formatMessage(messages.expireInvite)}>
                <YesNoField name="expireInvite" />
              </FormControl>
            </Show>
          </Show>
          <Show when={canAddDirectReports}>
            <UserMultiField
              data-cy="userDirectReports"
              label={intl.formatMessage({
                defaultMessage: "Direct reports",
                id: "home:widget:directReports",
              })}
              name="directReports"
              optional
              variables={{
                idsExclude: excludeUserIds.join(","),
              }}
            />
          </Show>
          <UserField
            data-cy="userManager"
            defaultItem={manager ?? undefined}
            label={intl.formatMessage({
              defaultMessage: "Manager",
              id: "settings:user:managerLabel",
            })}
            name="manager"
            optional
            variables={{
              idsExclude: excludeUserIds.join(","),
            }}
          />
          <br />
          <Show when={!isEdit}>
            <div className="inline-block">
              <FormattedMessage
                defaultMessage="By continuing, I agree to Perdoo's {linkToTerms} and {linkToPrivacy}."
                id="welcome:onboarding:form:terms"
                values={{
                  linkToPrivacy,
                  linkToTerms,
                }}
              />
            </div>
          </Show>
          <br />
          <Show when={showConfirmPassword}>
            <ConfirmPasswordModal
              isLoading={isSubmitting}
              onClose={toggleShowConfirmPassword}
              onCancel={() => {
                setFieldValue("password", undefined);
              }}
              onSubmit={submitForm}
            />
          </Show>
          <ModalFooter>
            <Button
              data-cy="save"
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={submitForm}
            >
              {intl.formatMessage({
                defaultMessage: "Save",
                id: "global:save",
              })}
            </Button>
            {!isEdit && (
              <Button
                data-cy="RdhNkjJrwO6eHef2BNF3s"
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={getAddAnotherHandler(submitForm)}
                variant="ghost"
              >
                {intl.formatMessage(messages.addAnother)}
              </Button>
            )}
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};
