import React from "react";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Icon } from "common/icons";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { twClass } from "utils/twClass";
import { useSelectContext } from "../Select.context";

export type Props = React.ButtonHTMLAttributes<HTMLDivElement> & {
  className?: string;
  clearable?: boolean;
  "data-cy"?: string;
  "data-testid"?: string;
  hasError?: boolean;
  innerClass?: string;
};

export const SelectValue = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      clearable = false,
      "data-cy": dataCy,
      "data-testid": dataTestId,
      disabled,
      hasError = false,
      innerClass,
      onClick,
      ...rest
    },
    ref
  ): JSX.Element => {
    const { isOpen, hasValue, clearValue } = useSelectContext();

    return (
      <div
        ref={ref}
        className={twClass(
          "flex h-10 w-full items-center divide-x rounded py-px",
          "group/select cursor-default transition-all ease-in-out",
          "border outline-none border-slate-300",
          "focus:ring-1 focus:ring-blue-500 focus:border-blue-500",
          "data-[state=open]:ring-1 data-[state=open]:ring-blue-500 data-[state=open]:border-blue-500",
          {
            "border-red-500": hasError,
            "cursor-not-allowed bg-slate-100": disabled,
          },
          className
        )}
        data-cy={dataCy ?? "selectValue"}
        data-testid={dataTestId ?? "selectValue"}
        data-state={isOpen ? "open" : "closed"}
        onClick={disabled ? undefined : onClick}
        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
        role="combobox"
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <div className="flex grow space-x-2 px-2 truncate">
          <div
            className={twClass(
              "grow",
              "truncate",
              { "text-slate-400": !hasValue },
              innerClass
            )}
          >
            {children}
          </div>

          {clearable && hasValue && (
            <div>
              <IconButton
                data-cy="ly6kOiX588KmCVoCUqoCL"
                className="text-slate-300 hover:text-slate-400"
                name="clear"
                onClick={(e) => {
                  e.stopPropagation();
                  clearValue();
                }}
              />
            </div>
          )}
        </div>
        <div className="flex items-center px-1.5">
          <Icon
            aria-hidden="true"
            className={twClass(
              "transition ease-in-out text-slate-300 group-hover/select:text-slate-400 group-focus/select:text-slate-800 data-[state=open]:text-slate-800"
            )}
            data-state={isOpen ? "open" : "closed"}
            name="keyboard_arrow_down"
            size="2xl"
          />
        </div>
      </div>
    );
  }
);

SelectValue.displayName = "SelectValue";
