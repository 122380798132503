import { FormattedMessage, useIntl } from "react-intl";
import { routes } from "route-configs";
import { EMPTY_TEXTAREA_VALUE } from "common/form/DescriptionForm/DescriptionForm";
import { TextEditor } from "common/inputs/TextEditor/TextEditor";
import { TextViewer } from "common/misc/TextViewer/TextViewer";
import { AnchorNext } from "common/navigation";
import { roles } from "constants/roles";
import { useCompany } from "hooks/useCompany/useCompany";
import { useCurrentUser } from "hooks/useCurrentUser/useCurrentUser";
import { useHasUserAccess } from "hooks/useHasUserAccess/useHasUserAccess";
import { useInTeams } from "hooks/useInTeams/useInTeams";

interface Props {
  autoFocus?: boolean;
  className?: string;
  clearOnCancel?: boolean;
  editable?: boolean;
  onBlur?: (value: string) => void;
  onCancel?: () => void;
  onChange?: (value: string) => void;
  onSubmit?: () => void;
  showActionsButtons?: boolean;
  showEditWarning?: boolean;
  showLastReflections?: boolean;
  showTemplate?: boolean;
  value: string | null;
}

export const CheckInReflection = ({
  autoFocus,
  className,
  clearOnCancel = true,
  editable,
  onCancel,
  onChange,
  onSubmit,
  showActionsButtons,
  showEditWarning,
  showLastReflections,
  showTemplate,
  value,
}: Props): JSX.Element => {
  const intl = useIntl();
  const isAdmin = useHasUserAccess(roles.admin);
  const inTeams = useInTeams();

  const currentUser = useCurrentUser({ fetchPolicy: "cache-and-network" });
  const lastReflection = currentUser?.latestCheckIn?.reflections;

  const company = useCompany();

  const handleChange = (val: string) => {
    let description = val;
    if (val === EMPTY_TEXTAREA_VALUE) {
      description = "";
    }
    onChange?.(description);
  };

  return (
    <div className={className}>
      <div className="space-y-4 lg:flex lg:items-stretch lg:space-x-4 lg:space-y-0">
        <div className="flex flex-col space-y-2 lg:flex-1">
          {showLastReflections && (
            <div className="text-sm font-semibold">
              <FormattedMessage
                defaultMessage="New review"
                id="OmJY8J"
              />
            </div>
          )}
          {editable ? (
            <div className="space-y-2">
              <TextEditor
                key="reflections"
                autoFocus={autoFocus}
                className="bg-white"
                clearOnCancel={clearOnCancel}
                clickOutsideWhitelist={["[data-cy=submitCheckIn"]}
                data-cy="checkInReflections"
                editWarning={
                  showEditWarning ? (
                    <FormattedMessage
                      defaultMessage="Your Review will only update in Perdoo, and not in Slack and the Weekly Report."
                      id="5OSnE+"
                    />
                  ) : undefined
                }
                minimizedView
                onCancel={onCancel}
                onChange={handleChange}
                onSubmit={onSubmit}
                persistTemplate
                placeholder={intl.formatMessage({
                  defaultMessage: "No Review",
                  id: 'eqKeRB',
                })}
                showActionsButtons={showActionsButtons}
                template={company.reflectionsTemplate}
                text={value ?? ""}
              />
            </div>
          ) : (
            <TextViewer
              className="grow overflow-auto bg-white"
              html={value}
              placeholderHtml={intl.formatMessage({
                defaultMessage: "No Review",
                id: 'eqKeRB',
              })}
            />
          )}
        </div>
        {showLastReflections && (
          <div className="lg:flex-1">
            {/* `h-0 min-h-full` is a trick to make the div not count towards the height of the container */}
            {/* Reference: https://stackoverflow.com/a/58782802/15992045 */}
            <div className="flex flex-col space-y-2 lg:h-0 lg:min-h-full">
              <div className="text-sm font-semibold">
                <FormattedMessage
                  defaultMessage="Last review"
                  id="NpUz+w"
                />
              </div>
              <TextViewer
                className="grow overflow-auto"
                html={lastReflection ?? null}
                placeholderHtml={intl.formatMessage({
                  defaultMessage: "No last review",
                  id: 'rDYnZ0',
                })}
              />
            </div>
          </div>
        )}
      </div>
      {!inTeams && isAdmin && showTemplate && (
        <div>
          <AnchorNext href={routes.settings.company.general.features}>
            <FormattedMessage
              defaultMessage="Change Review template"
              id="Zaeqqg"
            />
          </AnchorNext>
        </div>
      )}
    </div>
  );
};
