import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ObjectiveType } from "common/context/objectiveContext";
import { Icon } from "common/icons";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";

const maxRating = 5;

const messages = defineMessages({
  closedOn: {
    defaultMessage: "Closed on {completedDate}",
    id: "profile:objective:closed:completed:date",
  },
});

type Props = {
  objective: ObjectiveType;
};

export const ObjectiveClosedSubtext = ({
  objective,
}: Props): JSX.Element | null => {
  const intl = useIntl();

  const { closingNote } = objective;
  if (!closingNote?.closed) return null;
  return (
    <div className="flex">
      {!!closingNote.executionRating && (
        <div className="mr-2 flex border-r pr-2 border-slate-300">
          <Icon className="text-yellow-500" name="star" size="lg" />
          {closingNote.executionRating}/{maxRating}
        </div>
      )}
      <span>
        {intl.formatMessage(messages.closedOn, {
          completedDate: <FormattedDate value={closingNote.createdDate} />,
        })}
      </span>
    </div>
  );
};
