import { FormattedMessage } from "react-intl";
import { TextButton } from "common/buttons";
import { useObjectiveContext } from "common/context/objectiveContext";
import { Show } from "common/controlFlow";
import { TimeframeSelect } from "common/select/TimeframeSelect/TimeframeSelect";
import { FilterSet } from "legacy/components/filters/FilterSet";
import { AlignedObjectiveDropdown } from "./AlignedObjectiveDropdown/AlignedObjectiveDropdown";

interface Props {
  selectTfId: (tf?: string) => void;
  timeframe: any;
}

export const AlignedObjectivesHeader = ({ selectTfId, timeframe }: Props) => {
  const objective = useObjectiveContext();

  return (
    <div className="flex justify-between w-full items-center">
      <div className="flex items-center space-x-2">
        <div>
          <FormattedMessage
            defaultMessage="Aligned Objectives"
            id="explore:stats:alignedObjectives"
          />
        </div>
        <div
          className="py-0.25 rounded border px-1 text-xs bg-white"
          data-testid="alignedObjectiveCount"
        >
          {objective.children.edgeCount}
        </div>
      </div>
      <div className="flex space-x-4 items-center font-normal text-sm">
        <TimeframeSelect
          selectValueClassName="w-auto text-right"
          canSelectAll
          showDropdownIcon
          showSelectValueAsText
          selected={timeframe}
          onSelect={(timeframe) => selectTfId(timeframe?.id)}
        />
        <FilterSet
          customRender
          renderButton={() => (
            <TextButton
              data-cy="alignedObjectiveFilter"
              size="small"
              isDropdown
              dropdownIconSize="lg"
              text={<FormattedMessage defaultMessage="Filter" id="9Obw6C" />}
            />
          )}
        />
        <Show when={objective.canPatch}>
          <AlignedObjectiveDropdown objectiveId={objective.id} />
        </Show>
      </div>
    </div>
  );
};
