import { namedOperations, useDeleteObjectiveMutationMutation } from "@graphql";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Icon } from "common/icons";
import { ListItem } from "common/misc/ListItem/ListItem";
import { ConfirmDeletePermanently } from "common/overlay/ConfirmDeletePermanently/ConfirmDeletePermanently";
import { handleErrors } from "utils/graphql/handleErrors";
import { RefetchQueries } from "utils/graphql/types";
import { toast } from "utils/toastr";

const messages = defineMessages({
  objectiveDeletedMessage: {
    defaultMessage: "Objective deleted!",
    id: "gloabal:objective:deleted:message",
  },
});

interface Props {
  id: string;
  name?: string;
  onDelete?: () => void;
  onDeleteQueries?: RefetchQueries;
  showIcon?: boolean;
}

export const DeleteObjectiveAction = ({
  name,
  id,
  showIcon,
  onDelete,
  onDeleteQueries = [],
}: Props): JSX.Element => {
  const intl = useIntl();

  const [deleteObjective] = useDeleteObjectiveMutationMutation({
    refetchQueries: [
      namedOperations.Query.getRoadmap,
      namedOperations.Query.getStandardRoadmap,
      namedOperations.Query.SubObjectives,
      namedOperations.Query.getObjective,
      ...onDeleteQueries,
    ],
  });

  const removeObjective = async () => {
    const response = await deleteObjective({
      variables: {
        input: {
          id,
        },
      },
    });
    const { hasError } = handleErrors(
      response,
      response.data?.deleteObjective?.errors
    );

    if (hasError) return;

    if (onDelete) {
      onDelete();
    }
    toast.success(intl.formatMessage(messages.objectiveDeletedMessage));
  };

  return (
    <ConfirmDeletePermanently
      topPrio
      key="delete"
      body={
        <FormattedMessage
          defaultMessage="You are about to delete the Objective: {name}"
          id="+EB4+f"
          values={{
            name: <strong>{name}</strong>,
          }}
        />
      }
      trigger={
        <ListItem className="text-red-500">
          {showIcon && (
            <Icon
              className="mr-2 text-red-500"
              name="delete"
              size="2xl"
              type="outlined"
            />
          )}
          {intl.formatMessage({
            defaultMessage: "Delete Objective",
            id: "objective:delete",
          })}
        </ListItem>
      }
      onConfirm={removeObjective}
      entity={intl.formatMessage({
        defaultMessage: "Objective",
        id: "khNSj1",
      })}
    />
  );
};
