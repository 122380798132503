import {
  Objective,
  PerdooApiKeyResultStatusChoices,
  useGetObjectiveResultsQuery,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { omit, orderBy } from "lodash";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { Spinner } from "common/placeholders/Spinner/Spinner";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { ResultsEmptyState } from "../ResultsEmptyState/ResultsEmptyState";
import { ResultsFilterContext } from "../ResultsFilterContext/ResultsFilterContext";
import { ResultsWidget } from "./ResultsWidget/ResultsWidget";

type ObjectiveProp = Derive<Objective, { id: true; progressDriver: true }>;

type Props = {
  fallback?: JSX.Element;
  objective: ObjectiveProp;
};

export const ResultList = ({ objective, fallback }: Props): JSX.Element => {
  const filters = useContext(ResultsFilterContext);
  const { data, error } = useGetObjectiveResultsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      objectiveId: objective.id,
      ...omit(filters, ['archived', 'excludeAccomplished']),
    },
  });

  let results = mapEdges(data?.results.edges);

  if (isSet(filters.archived)) {
    results = results.filter((r) => !r.archived);
  }

  if (filters.excludeAccomplished) {
    results = results.filter(
      (i) => i.status !== PerdooApiKeyResultStatusChoices.Accomplished
    );
  }

  const results_ids = results.map((item) => item.id);
  const childLessResults = results.filter(
    (item) => !(item.parent?.id && results_ids.includes(item.parent.id))
  );

  const orderedResults = orderBy(childLessResults, ["index"], ["asc"]);

  if (!data) {
    return (
      <div className="mt-5 px-5 py-1">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="mt-5 px-5 py-1 text-sm">
        <FormattedMessage
          defaultMessage="Error loading results"
          id="user:profile:okrs:results:erro"
        />
      </div>
    );
  }

  return (
    <Show
      when={results.length > 0}
      // TODO: remove this default result empty state
      fallback={
        fallback ?? (
          <ResultsEmptyState
            progressDriver={objective.progressDriver}
            objectiveId={objective.id}
          />
        )
      }
    >
      <ResultsWidget results={orderedResults} />
    </Show>
  );
};
