import { PerdooApiKpiTargetOperatorChoices } from "@graphql";
import classNames from "clsx";
import { Field, FieldProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { ButtonGroup } from "common/buttons/ButtonGroup/ButtonGroup";
import { Show } from "common/controlFlow";

type Props = {
  errorText?: string;
  name: string;
  onChange?: (op: PerdooApiKpiTargetOperatorChoices) => void;
  setFieldValue: (name: string, value: string | null) => void;
};

export const OperatorSwitch = ({
  name,
  onChange,
  setFieldValue,
  errorText,
}: Props): JSX.Element => {
  const intl = useIntl();

  const options = [
    {
      label: "≥",
      tooltip: intl.formatMessage({
        defaultMessage: "Greater than or equal to",
        id: "kpi:forms:greaterThanOrEqualTo",
      }),
      value: "GREATER_THAN_OR_EQUAL",
    },
    {
      label: "≤",
      tooltip: intl.formatMessage({
        defaultMessage: "Less than or equal to",
        id: "kpi:forms:lessThanOrEqualTo",
      }),
      value: "LESS_THAN_OR_EQUAL",
    },
  ];

  const handleChange = (value: string) => {
    setFieldValue(name, value);
    onChange?.(value as PerdooApiKpiTargetOperatorChoices);
  };

  return (
    <div>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <>
            <ButtonGroup
              className={classNames("h-10", errorText && "border-red-500")}
              itemClass={classNames(
                "px-4 py-2 h-full",
                "text-sm leading-5 text-slate-800 hover:text-blue-500",
                "font-semibold font-sans"
              )}
              onChange={handleChange}
              options={options}
              value={field.value}
            />

            <Show when={errorText}>
              <div className="my-1 min-h-[18px] text-sm text-red-500">
                {errorText}
              </div>
            </Show>
          </>
        )}
      </Field>
    </div>
  );
};
