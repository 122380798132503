import {
  TimeframeDatesFragment,
  TimeframeDefaultFragment,
  TimeframeDurationFragment,
  TimeframeIsActiveFragment,
  TimeframeIsPastFragment,
  TimeframeLevelFragment,
  TimeframeObjectiveCountFragment,
  useBootPreloadTimeframeQueryQuery,
} from "@graphql";
import { orderBy } from "lodash";
import { mapEdges } from "utils/mapEdges";

type Timeframe = TimeframeDefaultFragment &
  TimeframeIsActiveFragment &
  TimeframeIsPastFragment &
  TimeframeObjectiveCountFragment &
  TimeframeDurationFragment &
  TimeframeDatesFragment &
  TimeframeLevelFragment & {
    objectives: { achievedCount: number | null };
  };

export type UseTimeframesHook = {
  activeTimeframes: Timeframe[];
  futureTimeframes: Timeframe[];
  pastTimeframes: Timeframe[];
  timeframes: Timeframe[];
};

type BasicTimeframe = {
  id: string;
  name: string;
};

export const useTimeframes = (
  availableTimeframes: BasicTimeframe[] = []
): UseTimeframesHook => {
  const { data } = useBootPreloadTimeframeQueryQuery();
  const allTimeframes = mapEdges(data?.timeframes.edges);

  const timeframesIdsSet = new Set(
    availableTimeframes.map((timeframe) => timeframe.id)
  );

  const filteredTimeframes = allTimeframes.filter((timeframe) =>
    timeframesIdsSet.has(timeframe.id)
  );

  const timeframesToSort =
    availableTimeframes.length > 0 ? filteredTimeframes : allTimeframes;

  const sortedTimeframes = orderBy(timeframesToSort, [
    (tf) => !tf.isActive,
    "startDate",
  ]);
  const activeTimeframes = sortedTimeframes.filter((tf) => tf.isActive);
  const futureTimeframes = sortedTimeframes.filter(
    (tf) => !tf.isActive && !tf.isPast
  );
  const pastTimeframes = orderBy(
    sortedTimeframes.filter((tf) => tf.isPast),
    "startDate",
    "desc"
  );

  return {
    activeTimeframes,
    futureTimeframes,
    pastTimeframes,
    timeframes: sortedTimeframes,
  };
};
