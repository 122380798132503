import { CommitStatus, KpiCardKpiFragment } from "@graphql";
import dayjs from "dayjs";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { StackedAvatar } from "common/avatar";
import { IconButton } from "common/buttons/IconButton/IconButton";
import { Status } from "common/charts/Status/Status";
import { CommitTooltip } from "common/commit/CommitTooltip/CommitTooltip";
import { Show } from "common/controlFlow";
import { GoalOwners } from "common/goal/GoalOwners/GoalOwners";
import { useSharedOwners } from "common/goal/GoalOwners/GoalOwners.utils";
import { useProgressUpdate } from "common/goal/hooks/useProgressUpdate";
import { IntegrationIcon } from "common/goal/IntegrationIcon/IntegrationIcon";
import { GoalUpdatedDate } from "common/goal/Outdated/GoalUpdatedDate";
import { ProgressUpdateButton } from "common/goal/ProgressUpdateButton/ProgressUpdateButton";
import { UpdateButton } from "common/goal/UpdateButton";
import { Icon } from "common/icons";
import { PrivateKpiLockButton } from "common/kpi/PrivateKpiLockButton/PrivateKpiLockButton";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { useFormatDate } from "common/misc/FormattedDate/hooks/useFormatDate";
import { FormattedMetric } from "common/misc/FormattedMetric/FormattedMetric";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { ProgressDelta } from "common/progress/ProgressDelta/ProgressDelta";
import { useInTeams } from "hooks/useInTeams/useInTeams";
import { usePrivateMode } from "hooks/usePrivateMode/usePrivateMode";
import { Dropdown } from "legacy/components/Dropdown/Dropdown";
import { twClass } from "utils/twClass";
import { useKpiData } from "../hooks/useKpiData";
import { KpiDropdown, KpiDropdownOption } from "../KpiDropdown/KpiDropdown";
import { formatGoalOperator } from "../utils";

type Props = {
  className?: string;
  disableUpdate?: boolean;
  dropdownOptions?: KpiDropdownOption[];
  kpi: KpiCardKpiFragment;
  onClick?: (id: string) => void;
  showGroups?: boolean;
};

export const Kpi = ({
  className = "",
  dropdownOptions,
  kpi,
  onClick,
  showGroups = false,
  disableUpdate = false,
}: Props): JSX.Element => {
  const {
    currentValue = 0,
    id,
    metricUnit,
    name,
    pushIntegrationApplication,
    groups,
    lastCommit,
    isCompanyGoal,
    isOutdated,
    archived,
  } = kpi;

  const inTeams = useInTeams();

  const { formatDate } = useFormatDate();

  const { handleSubmit } = useProgressUpdate(kpi);
  const [privateMode] = usePrivateMode();

  const { delta, deltaColor, healthTooltip, positiveMetric, isHealthy } =
    useKpiData(kpi);

  const sharedOwners = useSharedOwners(kpi.isCompanyGoal, kpi.groups);

  const tooltip = (
    <CommitTooltip
      commit={lastCommit}
      metricUnit={kpi.metricUnit}
      positiveMetric={positiveMetric}
      warning={!isHealthy ? healthTooltip : undefined}
    />
  );
  return (
    <Tooltip
      content={tooltip}
      theme="perdoo-light"
      spanClass="w-full h-full block"
    >
      <div
        className={twClass("group relative w-full", {
          // this is just a fallback, should be actually removed from the DOM wherever it's rendered
          "!hidden": privateMode && kpi.private,
        })}
        data-cy="kpiItem"
        data-testid="kpiItem"
      >
        <Show when={kpi.private}>
          <div className="absolute z-10 -left-2 -top-1">
            <PrivateKpiLockButton id={kpi.id} />
          </div>
        </Show>
        <div className="bg-white rounded border border-slate-300">
          <div
            className={twClass(
              "relative w-full my-3 pl-4 pr-3 bg-white",
              "flex flex-col grow truncate space-y-1",
              className
            )}
          >
            <div className="flex items-center space-x-2 justify-between w-full">
              <div className="flex items-center space-x-2 truncate">
                {(sharedOwners.length > 1 || showGroups) && (
                  <div className="flex mr-1">
                    <WithTooltip
                      tooltip={
                        <GoalOwners
                          className="p-0 text-white hover:bg-transparent"
                          goalId={kpi.id}
                          goalType="kpi"
                          isCompanyGoal={isCompanyGoal}
                          maxCount={2}
                          owners={groups}
                        />
                      }
                      interactive
                    >
                      <StackedAvatar
                        maxCount={2}
                        size="small"
                        urls={sharedOwners.map(
                          (avatar) => avatar.avatar as string
                        )}
                      />
                    </WithTooltip>
                  </div>
                )}

                {!!kpi.archivedDate && (
                  <WithTooltip
                    tooltip={
                      <FormattedMessage
                        defaultMessage="Archived on {archivedDate}"
                        values={{
                          archivedDate: formatDate(kpi.archivedDate, {
                            includeYear: true,
                          }),
                        }}
                        id="0Q8FKC"
                      />
                    }
                  >
                    <Icon className="cursor-default" name="archive" size="lg" />
                  </WithTooltip>
                )}
                <div className="truncate">
                  <button
                    className="pointer-events-none w-full cursor-pointer text-left text-slate-500 group-hover:text-blue-500 sm:pointer-events-auto truncate"
                    data-cy="kpi-name"
                    data-fs="hide"
                    data-testid="kpiName"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick?.(id);
                    }}
                    type="button"
                  >
                    <div className="truncate space-x-2">
                      <span>{name}</span>
                      {!!kpi.currentTarget &&
                        !!kpi.currentTarget.operator &&
                        !isNil(kpi.currentTarget.threshold) && (
                          <span>
                            ({formatGoalOperator(kpi.currentTarget.operator)}{" "}
                            <FormattedMetric
                              value={kpi.currentTarget.threshold}
                              unit={kpi.metricUnit}
                            />
                            )
                          </span>
                        )}
                    </div>
                  </button>
                </div>
              </div>
              <Show when={kpi.canPatch && !inTeams}>
                <Dropdown
                  button={
                    <IconButton
                      size="xl"
                      data-cy="kpiKebabButton"
                      data-testid="kpiKebabButton"
                      name="more_vert"
                    />
                  }
                  id="kpi-actions-menu"
                >
                  <KpiDropdown
                    dropdownOptions={dropdownOptions}
                    kpi={kpi}
                    onClickView={onClick}
                  />
                </Dropdown>
              </Show>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center space-x-1">
                {!isHealthy && (
                  <Status onlyIcon status={CommitStatus.NeedsAttention} />
                )}
                {pushIntegrationApplication && (
                  <IntegrationIcon
                    className="mr-1.5"
                    integration={pushIntegrationApplication}
                  />
                )}
                <div className="flex items-center">
                  <div
                    className="text-xl font-semibold"
                    data-cy="kpi-value"
                    data-testid="kpiValue"
                  >
                    <FormattedMetric
                      value={kpi.currentValue}
                      unit={kpi.metricUnit}
                    />
                  </div>
                </div>
              </div>

              <Show when={kpi.canPatch && !disableUpdate && !archived}>
                <div className="mr-1.5">
                  <ProgressUpdateButton
                    apiIntegration={pushIntegrationApplication}
                    backdating
                    initialValues={{
                      commitDate: dayjs().toISOString(),
                      value: currentValue,
                    }}
                    lastCommit={lastCommit}
                    metricUnit={metricUnit}
                    onSubmit={handleSubmit}
                    positiveMetric={positiveMetric}
                    type="kpi"
                    button={
                      <UpdateButton
                        size="small"
                        updateDue={isOutdated}
                        data-cy="updateProgressButton"
                        data-testid="progressUpdateButton"
                      />
                    }
                  />
                </div>
              </Show>
            </div>
          </div>

          {(lastCommit || delta !== 0) && (
            <div className="flex justify-between border-t border-slate-300 py-2 pl-2 pr-2 w-full space-x-2">
              <div>
                <ProgressDelta
                  color={deltaColor}
                  data-testid="kpiDelta"
                  value={delta}
                />
              </div>
              {lastCommit && (
                <div className="flex items-center text-sm text-slate-500 truncate">
                  <Show
                    when={isOutdated}
                    fallback={
                      <FormattedMessage
                        defaultMessage="Updated on {date}"
                        values={{
                          date: <FormattedDate value={lastCommit.commitDate} />,
                        }}
                        id="N3Jd7a"
                      />
                    }
                  >
                    <div className="truncate">
                      <GoalUpdatedDate lastCommitDate={lastCommit.commitDate} />
                    </div>
                  </Show>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
