import {
  useGetUserProgressReportBaseQuery,
  useGetUserProgressReportObjectivesQuery,
} from "@graphql";
import dayjs from "dayjs";
import { isEmpty, isNil } from "lodash";
import { FormattedMessage } from "react-intl";
import { Show } from "common/controlFlow";
import { FormattedDate } from "common/misc/FormattedDate/FormattedDate";
import { SpinnerFit } from "common/placeholders/SpinnerFit/SpinnerFit";
import { CheckInReflection } from "modules/checkIn/CheckInContainer/CheckIn/CheckInForm/CheckInReflection/CheckInReflection";
import { CheckInPulseSection } from "modules/users/[id]/progressReports/[id]/UserProgressReportDetails/CheckInPulseSection/CheckInPulseSection";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { SidebarCheckInKpi } from "./SidebarCheckInKpi/SidebarCheckInKpi";
import { SidebarCheckInObjective } from "./SidebarCheckInObjective/SidebarCheckInObjective";

type Props = {
  cycleStart: string;
  userId: string;
};

export const CheckInDetails = ({ cycleStart, userId }: Props): JSX.Element => {
  const { data, error } = useGetUserProgressReportBaseQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      cycleStart: dayjs(cycleStart).format("YYYY-MM-DD"),
      userId: userId,
    },
  });
  const { data: objectiveData } = useGetUserProgressReportObjectivesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      cycleStart: dayjs(cycleStart).format("YYYY-MM-DD"),
      userId: userId,
    },
  });

  if (error) return <div>{error.message}</div>;

  if (isNil(data) || isNil(objectiveData) || isNil(data.userProgressReport)) {
    return <SpinnerFit />;
  }

  const report = data.userProgressReport;
  const objectiveReport = objectiveData.userProgressReport;

  const kpisToShow = mapEdges(report.kpis.edges).filter(
    (kpi) => kpi.delta.commitCount > 0
  );

  const okrsWithUpdates = mapEdges(objectiveReport?.objectives.edges).map(
    (objective) => ({
      ...objective,
      results: mapEdges(objective.results?.edges).filter(
        (result) => result.delta.commitCount > 0
      ),
    })
  );

  const okrsToShow = okrsWithUpdates.filter((okr) => okr.results.length > 0);

  const allCheckIns = mapEdges(data.userProgressReport.reflections.edges);
  const isOutOfOffice = allCheckIns.some((checkIn) => checkIn.isOutOfOffice);
  const checkIns = allCheckIns.filter((checkIn) => !checkIn.isOutOfOffice);
  const hasOkrs = !isEmpty(okrsToShow);
  const hasKpis = !isEmpty(kpisToShow);
  const hasGoals = hasOkrs || hasKpis;
  const hasCheckIns = !isEmpty(checkIns);

  return (
    <div
      className="py-6 space-y-6"
      data-cy="checkInDetailsContainer"
      data-testid="checkInDetailsContainer"
    >
      <Show when={isOutOfOffice}>
        <div className="text-slate-500">
          <FormattedMessage
            defaultMessage="This person was out of office this cycle."
            id="gVkh7Q"
          />
        </div>
      </Show>
      <Show when={!isOutOfOffice && !hasCheckIns && !hasGoals}>
        <div className="text-slate-500">
          <FormattedMessage
            defaultMessage="No updates or Review was submitted in this cycle."
            id="YamVxc"
          />
        </div>
      </Show>
      <Show when={hasCheckIns}>
        <div className="space-y-3">
          <h4 className="leading-none">
            <FormattedMessage defaultMessage="Review" id="R+J5ox" />
          </h4>
          <div className="rounded border bg-white border-slate-300 divide-y divide-slate-300">
            {checkIns.map((checkIn) => (
              <div key={checkIn.id} className="p-4">
                <div
                  className="flex items-center space-x-1 first:pt-0 last:pb-0 whitespace-nowrap"
                  id="checkInSubmittedSubtext"
                >
                  {!!checkIn.submitted ? (
                    <FormattedMessage
                      defaultMessage="Submitted on {date}"
                      id="nklYpE"
                      values={{
                        date: (
                          <FormattedDate
                            includeYear={false}
                            value={checkIn.submitted}
                          />
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Review"
                      id="R+J5ox"
                    />
                  )}
                </div>
                <CheckInReflection
                  className="py-4 first:pt-0 last:pb-0"
                  value={checkIn.reflections}
                />
                <Show when={isSet(checkIn.pulse)}>
                  <CheckInPulseSection
                    className="py-4 first:pt-0 last:pb-0"
                    comment={checkIn.pulseComment}
                    pulse={checkIn.pulse}
                  />
                </Show>
              </div>
            ))}
          </div>
        </div>
      </Show>
      <Show when={hasKpis}>
        <div className="space-y-3">
          <h4 className="leading-none">
            <FormattedMessage defaultMessage="KPIs" id="global:KPIs:fixed" />
          </h4>
          <div className="space-y-2">
            {kpisToShow.map((kpi) => (
              <SidebarCheckInKpi key={kpi.id} kpi={kpi} />
            ))}
          </div>
        </div>
      </Show>
      <Show when={hasOkrs}>
        <div className="space-y-3">
          <h4 className="leading-none">
            <FormattedMessage defaultMessage="OKRs" id="XvfcPr" />
          </h4>
          {okrsToShow.map((objective) => (
            <SidebarCheckInObjective key={objective.id} objective={objective} />
          ))}
        </div>
      </Show>
    </div>
  );
};
