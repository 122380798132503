import React from "react";
import { useIntl } from "react-intl";
import { Placement } from "tippy.js";
import { Icon } from "common/icons/Icon/Icon";
import { Anchor } from "common/navigation/Anchor/Anchor";
import { WithTooltip } from "common/overlay/WithTooltip/WithTooltip";
import { messages } from "./OKROverload.messages";

interface Props {
  placement?: Placement;
  type: string;
}
export const OKROverload = ({ placement = "top", type }: Props) => {
  const intl = useIntl();
  return (
    <WithTooltip
      placement={placement}
      tooltip={
        <div className="w-auto text-base">
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {intl.formatMessage(messages[type])}
          <br />

          {intl.formatMessage(
            {
              defaultMessage:
                "For more info about OKR best practices, check out our {link}.",
              id: "objective:kr:overload:note2",
            },
            {
              link: (
                <Anchor
                  href="https://www.perdoo.com/resources/"
                  text="Resources Hub"
                />
              ),
            }
          )}
        </div>
      }
    >
      <Icon className="visible text-red-500" name="error_outline" size="lg" />
    </WithTooltip>
  );
};
