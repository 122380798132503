import { QueryOptions } from "@apollo/client";
import {
  ClosingNoteStatus,
  CommitStatus,
  CompanyClosingQuestionTemplatesFragment,
  CompanyStretchGoalsFragment,
  namedOperations,
  ObjectiveClosingNoteFragment,
  ObjectiveDefaultFragment,
  ObjectiveGroupsFragment,
  ObjectiveProgressFragment,
  ObjectiveStatusFragment,
  useGetCompanyTemplatesQuery,
  useUpsertClosingNoteMutation,
} from "@graphql";
import { FormikHelpers, FormikProps } from "formik";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { StackedAvatar } from "common/avatar";
import {
  CloseObjForm,
  CloseObjFormValues,
} from "common/form/CloseObjForm/CloseObjForm";
import { GoalOwners } from "common/goal/GoalOwners/GoalOwners";
import { useSharedOwners } from "common/goal/GoalOwners/GoalOwners.utils";
import { ConfirmationModal } from "common/overlay/ConfirmationModal/ConfirmationModal";
import { Modal } from "common/overlay/Modal/Modal";
import { Tooltip } from "common/overlay/Tooltip/Tooltip";
import { CANCEL_CLOSE_OBJECTIVE, CLOSE_OBJECTIVE } from "constants/tracking";
import { useOutsideAlerter } from "hooks/useOutsideAlerter/useOutsideAlerter";
import { handleErrors } from "utils/graphql/handleErrors";
import { isSet } from "utils/isSet";
import { mapEdges } from "utils/mapEdges";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { messages } from "./CloseObjectiveModal.messages";

interface Props {
  objective: ObjectiveDefaultFragment &
    ObjectiveGroupsFragment &
    ObjectiveStatusFragment &
    ObjectiveClosingNoteFragment &
    ObjectiveProgressFragment;
  onRequestClosed: () => void;
  open: boolean;
  refetchQueries?: (string | QueryOptions)[];
}
export type CloseObjectiveModalProps = Props;

const initialValuesFromObjective = (
  objective: ObjectiveDefaultFragment &
    ObjectiveStatusFragment &
    ObjectiveClosingNoteFragment &
    ObjectiveProgressFragment,
  company: CompanyClosingQuestionTemplatesFragment & CompanyStretchGoalsFragment
): CloseObjFormValues => {
  if (isSet(objective.closingNote)) {
    const existing = objective.closingNote;
    return {
      closed: existing.closed,
      executionRating: existing.executionRating,
      id: existing.id,
      objective: objective.id,
      questions: mapEdges(existing.questions.edges).map((question) => ({
        answer: question.answer ?? "",
        isRequired: question.isRequired,
        text: question.text,
      })),
      status: existing.status,
    };
  }

  const objectiveIsAccomplished =
    objective.status === CommitStatus.Accomplished;
  const objectiveHasGoodProgress =
    (company.stretchGoals && (objective.progress ?? 0) >= 70) ||
    (!company.stretchGoals && (objective.progress ?? 0) >= 100);
  const status =
    objectiveIsAccomplished || objectiveHasGoodProgress
      ? ClosingNoteStatus.Achieved
      : ClosingNoteStatus.Missed;

  return {
    closed: true,
    executionRating: null,
    id: null,
    objective: objective.id,
    questions: mapEdges(company.closingQuestionTemplates.edges).map(
      (template) => ({
        answer: "",
        isRequired: template.isRequired,
        text: template.text,
      })
    ),
    status,
  };
};

export const CloseObjectiveModal = ({
  open,
  onRequestClosed,
  objective,
  refetchQueries = [],
}: Props): JSX.Element | null => {
  const intl = useIntl();
  const { data } = useGetCompanyTemplatesQuery();
  const [upsertClosingNote] = useUpsertClosingNoteMutation({
    refetchQueries,
  });
  const sharedOwners = useSharedOwners(
    objective.isCompanyGoal,
    objective.groups
  );

  const formRef = useRef<FormikProps<CloseObjFormValues>>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const cancelModal = () => {
    track(CANCEL_CLOSE_OBJECTIVE);
    onRequestClosed();
  };
  const handleClose = () => {
    const formTouched = formRef.current?.dirty ?? false;
    if (formTouched && !showConfirm) {
      setShowConfirm(true);
    } else {
      cancelModal();
    }
  };

  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(modalRef, handleClose, [
    "#dropdown-root",
    "#modal-confirm",
    "#overlay-root",
    "#text-editor-tools-root",
    // not sure why, but clicking on the status in Select registers as an outside click. so whitelisting explicitly here.
    "#statusElement",
    ".tippy-box",
    ".modalfooter",
  ]);

  if (!data) return null;

  const isEdit = isSet(objective.closingNote);

  const initialValues = initialValuesFromObjective(
    objective,
    data.currentCompany
  );

  const onSubmit = async (
    values: CloseObjFormValues,
    actions: FormikHelpers<CloseObjFormValues>
  ) => {
    const response = await upsertClosingNote({
      refetchQueries: [
        namedOperations.Query.getUserProgressReportObjectives,
        namedOperations.Query.getGroupProgressReportObjectives,
        namedOperations.Query.getObjective,
      ],
      variables: {
        input: values,
      },
    });
    actions.setSubmitting(false);
    const { hasError } = handleErrors(
      response,
      response.data?.upsertClosingNote?.errors
    );
    if (hasError) return;

    const isPatch = isSet(values.id);
    const successMessage = isPatch
      ? intl.formatMessage(messages.closingNoteUpdated)
      : intl.formatMessage(messages.objectiveClosed);
    toast.success(successMessage);

    const hasAnswers = values.questions.some(
      (question) => !isEmpty(question.answer)
    );
    track(CLOSE_OBJECTIVE, {
      closing_status: values.status,
      has_answers: hasAnswers,
      rating: values.executionRating,
    });
    onRequestClosed();
  };

  const title = isEdit
    ? intl.formatMessage(messages.editClosingNote)
    : intl.formatMessage(messages.closeTitle);
  return (
    <Modal
      backdropClass="!z-modal-prio-backdrop"
      backdropClose={false}
      className="!z-modal-prio"
      isOpen={open}
      onClose={handleClose}
      size="md"
      title={title}
    >
      <div ref={modalRef} className="divide-y">
        {isSet(objective.groups) && (
          <div className="flex items-center py-5">
            <Tooltip
              content={
                <GoalOwners
                  className="p-0 text-white hover:bg-transparent"
                  goalId={objective.id}
                  goalType="objective"
                  isCompanyGoal={objective.isCompanyGoal}
                  maxCount={2}
                  owners={objective.groups}
                />
              }
              interactive
              maxWidth="450px"
            >
              <StackedAvatar
                maxCount={2}
                size="medium"
                urls={sharedOwners.map((group) => group.avatar as string)}
              />
            </Tooltip>
            <div className="ml-2">{objective.name}</div>
          </div>
        )}
        <CloseObjForm
          className="py-5"
          formRef={formRef}
          initialValues={initialValues}
          onCancel={handleClose}
          onSubmit={onSubmit}
        />
      </div>
      {showConfirm && (
        <ConfirmationModal
          action={cancelModal}
          close={() => setShowConfirm(false)}
          confirmText={
            <FormattedMessage defaultMessage="Confirm" id="global:confirm" />
          }
          title={
            <FormattedMessage
              defaultMessage="Cancel closing"
              id="closeObjectiveModal:confirmClose:title"
            />
          }
        >
          <FormattedMessage
            defaultMessage="Are you sure you want to cancel the closing process? All the information you have input will be lost"
            id="closeObjectiveModal:confirmClose:description"
          />
        </ConfirmationModal>
      )}
    </Modal>
  );
};
