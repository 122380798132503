import { useMutation } from "@apollo/client";
import {
  CommitStatus,
  KeyResult,
  namedOperations,
  PerdooApiKeyResultTypeChoices,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { ListItem } from "common/misc/ListItem/ListItem";
import { objectiveStages } from "common/objective/constants";
import { OBJECTIVE_QUERY } from "common/objective/queries";
import { useConfirmationModal } from "common/overlay/ConfirmationModal/hooks/useConfirmationModal";
import { MODAL_TYPES } from "constants/index";
import { objectTypes } from "constants/objects";
import { resultTypes } from "constants/resultTypes";
import { DELETE_KEY_RESULT } from "constants/tracking";
import { useCommitActions } from "hooks/useCommitActions/useCommitActions";
import { showModal } from "legacy/actions/actions";
import { handleNonFormErrors } from "utils/forms";
import { toast } from "utils/toastr";
import { track } from "utils/tracker";
import { DELETE_RESULT_MUTATION, RESULT_MUTATION } from "../queries";
import { ResultsFilterContext } from "../ResultsFilterContext/ResultsFilterContext";

const messages = defineMessages({
  addAnother: {
    defaultMessage: "Add another",
    id: "global:add:another",
  },
  deleteResult: {
    defaultMessage: "Delete Result",
    id: "global:delete:result",
  },
  editResult: {
    defaultMessage: "Edit Result",
    id: "result:edit",
  },
  markComplete: {
    defaultMessage: " Mark complete",
    id: "initiative:markComplete",
  },
});

const showToggleArchiveResultToast = (result: {
  archived: boolean;
  id: string;
}) => {
  if (result.archived) {
    toast.success("Result Archived");
  } else {
    toast.success("Result Restored");
  }
};

export type ResultDropdownOption =
  | "archive"
  | "addInitiative"
  | "delete"
  | "update"
  | "move"
  | "edit";

type ResultProp = Derive<
  KeyResult,
  {
    archived: true;
    canPatch: true;
    childrenCount: true;
    currentValue: true;
    endValue: true;
    id: true;
    name: true;
    normalizedValue: true;
    objective: {
      id: true;
      stage: true;
    };
    type: true;
  }
>;

type Props = {
  dropdownOptions?: ResultDropdownOption[];
  onClickUpdate?: () => void;
  onEdit?: () => void;
  onMove?: () => void;
  onRemove?: () => void;
  result: ResultProp;
};

/**
 * returns an array of list items to put in the dropdown. items are actions like "update progress", "edit result".
 */
export const useResultActions = ({
  onClickUpdate,
  onMove,
  dropdownOptions = ["edit", "delete", "addInitiative", "archive", "move"],
  onRemove,
  onEdit,
  result,
}: Props): JSX.Element[] | null => {
  const { archived, canPatch, currentValue, endValue, name, objective, type } =
    result;
  const { openModal: openConfirmationModal } = useConfirmationModal();
  const dispatch = useDispatch();
  const intl = useIntl();
  const filters = useContext(ResultsFilterContext);

  const queriesToRefetchAfterResultChanged = [
    {
      query: OBJECTIVE_QUERY,
      variables: {
        objectiveId: objective.id,
      },
    },
    namedOperations.Query.resultsQuery,
    namedOperations.Query.getObjective,
    namedOperations.Query.getObjectiveTimeline,
    namedOperations.Query.getObjectiveResults,
    namedOperations.Query.getUserOkrs,
  ];

  const [editResult] = useMutation(RESULT_MUTATION, {
    refetchQueries: queriesToRefetchAfterResultChanged,
  });
  const [deleteKeyResult] = useMutation(DELETE_RESULT_MUTATION, {
    refetchQueries: queriesToRefetchAfterResultChanged,
  });
  const { addCommit } = useCommitActions();

  const canUpdateProgress = objective.stage !== objectiveStages.closed;
  const active = !archived && canUpdateProgress;

  if (!canPatch) return null;

  const onDelete = () => {
    deleteKeyResult({
      variables: {
        input: {
          id: result.id,
        },
      },
    })
      .then(() => {
        toast.success("Result Deleted");
        track(DELETE_KEY_RESULT);
        onRemove?.();
      })
      .catch(() => toast.failure("Error Deleting Result"));
  };

  const onArchiveToggle = (data: { archived: boolean; id: string }) => {
    showToggleArchiveResultToast(data);
  };

  const moveResult = () => {
    dispatch(
      showModal(MODAL_TYPES.MOVE_RESULT, {
        callback: onMove,
        objectiveId: objective.id,
        refetchQueries: queriesToRefetchAfterResultChanged,
        result,
      })
    );
  };

  const toggleArchiveResult = () => {
    const input = {
      archived: !archived,
      id: result.id,
    };

    if (archived) {
      editResult({
        variables: {
          input,
        },
      }).then((response) => {
        const { errors } = response.data.upsertResult;
        if (errors.length) {
          handleNonFormErrors(errors);
          return;
        }
        onArchiveToggle(input);
      });
    } else {
      dispatch(
        showModal(MODAL_TYPES.ARCHIVE_RESULT, {
          callback: () => onArchiveToggle(input),
          id: result.id,
          objectiveId: objective.id,
          progress: result.normalizedValue,
        })
      );
    }
  };

  const openAddInitiativeModal = () => {
    dispatch(
      showModal(MODAL_TYPES.ADD_RESULT, {
        filters,
        objectiveId: objective.id,
        parentId: result.id,
        reFetchQueries: ["getUserOkrs", "resultsQuery"],
      })
    );
  };

  const updateToComplete = () => {
    const values = {
      keyResult: result.id,
      status: CommitStatus.Accomplished,
      value: endValue,
    };
    addCommit(values, objectTypes.keyresult);
  };

  const openConfirmDeleteModal = () => {
    openConfirmationModal({
      action: onDelete,
      children: (
        <FormattedMessage
          defaultMessage="Are you sure you want to delete the {resultType}: {name}?"
          id="yVV23q"
          values={{
            name: <strong>{name}</strong>,
            resultType:
              result.type === PerdooApiKeyResultTypeChoices.KeyResult ? (
                <FormattedMessage defaultMessage="Key Result" id="b+726N" />
              ) : (
                <FormattedMessage defaultMessage="Initiative" id="jpmEhT" />
              ),
          }}
        />
      ),
      confirmText: intl.formatMessage({
        defaultMessage: "Confirm",
        id: "global:confirm",
      }),
      title: intl.formatMessage(messages.deleteResult),
    });
  };

  const actions: JSX.Element[] = [];

  if (
    active &&
    type === resultTypes.keyResult &&
    dropdownOptions.includes("addInitiative")
  ) {
    actions.push(
      <ListItem
        data-cy="actionAddInitiative"
        key="add"
        onClick={openAddInitiativeModal}
      >
        <FormattedMessage defaultMessage="Add Initiative" id="initiative:add" />
      </ListItem>
    );
  }

  if (active && currentValue < endValue && type === resultTypes.initiative) {
    actions.push(
      <ListItem
        data-cy="actionMarkComplete"
        key="mark_complete"
        onClick={updateToComplete}
      >
        {intl.formatMessage(messages.markComplete)}
      </ListItem>
    );
  }

  if (active && dropdownOptions.includes("update")) {
    actions.push(
      <ListItem
        data-cy="actionUpdateProgress"
        key="update_progress"
        onClick={onClickUpdate}
      >
        {intl.formatMessage({
          defaultMessage: "Update progress",
          id: "modal:update:progress",
        })}
      </ListItem>
    );
  }

  if (dropdownOptions.includes("move"))
    actions.push(
      <ListItem data-cy="actionMoveResult" key="move" onClick={moveResult}>
        <FormattedMessage defaultMessage="Move Result" id="6qiFiY" />
      </ListItem>
    );

  if (dropdownOptions.includes("edit"))
    actions.push(
      <ListItem data-cy="actionEditResult" key="edit" onClick={onEdit}>
        <FormattedMessage defaultMessage="Edit Result" id="a7zc5p" />
      </ListItem>
    );

  if (dropdownOptions.includes("archive"))
    actions.push(
      <ListItem
        data-cy="actionArchiveResult"
        key="archive"
        onClick={toggleArchiveResult}
      >
        {archived ? (
          <FormattedMessage defaultMessage="Restore Result" id="7asMIE" />
        ) : (
          <FormattedMessage defaultMessage="Archive Result" id="2QYlnt" />
        )}
      </ListItem>
    );

  if (dropdownOptions.includes("delete"))
    actions.push(
      <ListItem
        data-cy="deleteKeyResult"
        key="delete"
        onClick={openConfirmDeleteModal}
      >
        {intl.formatMessage(messages.deleteResult)}
      </ListItem>
    );

  return actions;
};
